import {
  Question,
  Option,
  SelectedOptionsState,
  ShortAnswersState,
} from '@squareup/dex-types-shared-app-launch';
import { Box } from '@squareup/dex-ui-shared-base';
import React, { FC } from 'react';

import { RequirementQuestion } from '../requirement-question';

interface QuestionsListProps {
  questions: Question[];
  selectedOptions: SelectedOptionsState;
  shortAnswers: ShortAnswersState;
  domainName: string;
  trackingId: string;
  showEditLinks?: boolean;
  questionsRefs?:
    | React.MutableRefObject<Map<string, HTMLElement | null>>
    | undefined;
  onOptionSelected(option: Option, parentQuestion: Question): void;
  onShortAnswerChange(newValue: string, parentQuestion: Question): void;
}

const QuestionsList: FC<QuestionsListProps> = ({
  questions,
  selectedOptions,
  shortAnswers,
  domainName,
  onOptionSelected,
  onShortAnswerChange,
  questionsRefs,
  trackingId,
  showEditLinks = false,
}) => {
  return (
    <Box testId="requirement-questions-list">
      {questions.map((question) => {
        return (
          <RequirementQuestion
            key={question.id}
            question={question}
            shortAnswers={shortAnswers}
            domainName={domainName}
            selectedOptions={selectedOptions}
            onOptionSelected={onOptionSelected}
            onShortAnswerChange={onShortAnswerChange}
            trackingId={trackingId}
            questionsRefs={questionsRefs}
            showEditLink={showEditLinks}
          />
        );
      })}
    </Box>
  );
};

export { type QuestionsListProps, QuestionsList };
