import * as React from 'react';
import { SVGProps } from 'react';

const SvgEllipse = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m17.975 13.012-.995-.105-.995-.104a7.11 7.11 0 0 0-.005-1.47l.995-.101.994-.102a9.142 9.142 0 0 1 .006 1.882Zm-.742-4.604-.912.406-.912.406a7.034 7.034 0 0 0-.737-1.267l.806-.587.807-.588c.37.506.688 1.052.948 1.63Zm-2.946-3.624-.586.808-.585.808a7.03 7.03 0 0 0-1.27-.734l.405-.913.404-.913a9.047 9.047 0 0 1 1.632.944ZM9.932 3.11l-.1.994-.1.995a7.11 7.11 0 0 0-1.468-.002l-.107-.995-.107-.995a9.097 9.097 0 0 1 1.882.003Zm-4.6.721.41.914.41.914c-.447.2-.87.445-1.263.731l-.591-.81-.592-.809a8.938 8.938 0 0 1 1.626-.94Zm-3.61 2.93.81.589.81.59c-.285.393-.53.816-.728 1.265L1.7 8.795l-.914-.408c.255-.578.57-1.123.937-1.628ZM.066 11.105a9.099 9.099 0 0 0 .006 1.882l.995-.102.994-.102a7.112 7.112 0 0 1-.005-1.468l-.995-.105-.995-.105ZM.81 15.71l.912-.406.912-.406c.201.449.449.873.737 1.267l-.807.588-.807.587a9.044 9.044 0 0 1-.947-1.63Zm2.945 3.624.586-.808.586-.808c.394.287.82.534 1.269.735l-.405.912-.404.913a9.042 9.042 0 0 1-1.632-.944Zm4.355 1.675.1-.994.1-.995a7.11 7.11 0 0 0 1.469.002l.107.995.106.995a9.083 9.083 0 0 1-1.882-.003Zm4.6-.721-.41-.914-.41-.914c.448-.2.871-.445 1.264-.73l.591.808.591.81a8.94 8.94 0 0 1-1.626.94Zm3.61-2.93-.81-.589-.81-.59c.285-.392.53-.816.728-1.264l.915.408.915.408a8.936 8.936 0 0 1-.937 1.628Z"
      fill="#000"
      fillOpacity={0.15}
    />
  </svg>
);
export default SvgEllipse;
