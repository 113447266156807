import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { TestProps } from '@squareup/dex-types-shared-utils';
import { Box } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, {
  FC,
  PropsWithChildren,
  RefObject,
  useEffect,
  useRef,
} from 'react';

import styles from './sticky-container.module.css';
import { addAttrWhenSticky } from './sticky-utils';

interface StickyContainerProps {
  scrollableRef: RefObject<HTMLElement>;
}

const StickyContainer: FC<
  PropsWithChildren<StickyContainerProps & NullableClassName & TestProps>
> = ({ children, scrollableRef, className, testId }) => {
  const containerRef = useRef<HTMLElement>(null);
  const navDefaultMaxHeight = useRef<number>();

  useEffect(() => {
    let observer: IntersectionObserver | undefined;
    let animationFrameId: number;

    function onFirstRender() {
      if (
        containerRef.current &&
        typeof navDefaultMaxHeight.current === 'undefined'
      ) {
        observer = addAttrWhenSticky(containerRef.current);
        if (scrollableRef.current) {
          // Save the current max height before scolling. We use this to calculate
          // how much larger the actual nav will be before being "stuck"
          navDefaultMaxHeight.current = Number.parseInt(
            getComputedStyle(scrollableRef.current).maxHeight,
            10
          );
        }
      }
    }

    /**
     * On scroll, we must do the following:
     * 1. If we have hit the "sticky" point of the element,
     * default to the max-height set in CSS and remove our inline 'max-height'
     * 2. Otherwise, we're at the point before the sticky. Take the initial
     * max height, and increase it by the current scroll
     */
    function onScroll() {
      onFirstRender();
      if (
        !scrollableRef.current ||
        !containerRef.current ||
        !navDefaultMaxHeight.current
      ) {
        return;
      }

      const scrollTop = document.documentElement.scrollTop;
      const marginBottom = Number.parseInt(
        getComputedStyle(scrollableRef.current).top.replace('px', ''),
        10
      );

      if (
        containerRef.current.classList.contains(
          styles['is-stickied'] as string
        ) ||
        scrollTop >= containerRef.current.getBoundingClientRect().top
      ) {
        return;
      }

      const currentMaxHeight = navDefaultMaxHeight.current;

      animationFrameId = requestAnimationFrame(() => {
        if (scrollableRef && scrollableRef.current) {
          // eslint-disable-next-line no-param-reassign
          scrollableRef.current.style.maxHeight = `${
            currentMaxHeight + scrollTop - marginBottom
          }px`;
        }
      });
    }

    document.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      document.removeEventListener('scroll', onScroll);
      observer?.disconnect();
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [scrollableRef, containerRef]);

  return (
    <Box
      ref={containerRef}
      className={clsx(styles.container, className)}
      testId={testId}
    >
      {children}
    </Box>
  );
};

export { StickyContainer };
