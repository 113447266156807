import { Box } from '@squareup/dex-ui-shared-base';
import React, { useEffect, useRef } from 'react';

import { SvelteClientSideComponentConstructor } from './SvelteComponent';

type DynamicSvelteComponentProps<
  // eslint-disable-next-line no-use-before-define
  T extends SvelteClientSideComponentConstructor<T, P>,
  P
> = {
  clientSideComponent: Promise<T>;
  componentProps: P;
};

export const DynamicSvelteComponent = <
  // eslint-disable-next-line no-use-before-define
  T extends SvelteClientSideComponentConstructor<T, P>,
  P
>({
  componentProps,
  clientSideComponent,
}: DynamicSvelteComponentProps<T, P>) => {
  const container = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const element = useRef<null | any>(null);

  useEffect(() => {
    async function run() {
      if (!element.current) {
        const Component = await clientSideComponent;

        // Between mounts the container may become null. Never mount the component to a null container.
        if (container.current && !element.current) {
          // eslint-disable-next-line require-atomic-updates
          element.current = new Component({
            hydrate: true,
            props: componentProps,
            target: container.current,
          });
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    run();

    return () => {
      element.current?.$destroy();
      element.current = undefined;
    };
  }, [componentProps, clientSideComponent]);

  return <Box ref={container}></Box>;
};
