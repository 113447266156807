import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { TrackedProps } from '@squareup/dex-types-shared-utils';
import { Paragraph10, Paragraph20 } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import { focusRingStyles } from '../FocusRing';
import { Link } from '../Link';

import styles from './doc-breadcrumb.module.css';

interface BreadcrumbProps {
  href?: string | undefined;
  label?: string | undefined;
}

const LinkWrapper: FC<
  PropsWithChildren<
    { href?: string | undefined } & NullableClassName & TrackedProps
  >
> = ({ children, href, trackingId }) => {
  return href ? (
    <Link passHref omitAnchor={true} href={href} trackingId={trackingId}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

const Breadcrumb: FC<PropsWithChildren<BreadcrumbProps & TrackedProps>> = ({
  children,
  href,
  trackingId,
  label,
}) => {
  return (
    <LinkWrapper href={href} trackingId={trackingId}>
      <Paragraph20
        as={href ? 'a' : 'div'}
        aria-label={href && label ? label : undefined}
        colorVariant={'20'}
        className={clsx(styles.breadcrumb, focusRingStyles['focus-ring'])}
      >
        {children}
      </Paragraph20>
    </LinkWrapper>
  );
};

const BreadcrumbDivier: FC = () => {
  return (
    <Paragraph10 margin={{ horizontal: '0.5x' }} colorVariant="30">
      /
    </Paragraph10>
  );
};

export { Breadcrumb, BreadcrumbDivier };
