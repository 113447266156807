import { captureException } from '@sentry/react';
import { OneTrustCategory } from '@square/onetrust-compliant-access';
import { UserInfo } from '@squareup/dex-types-shared-developer-data-api';
import { useEffect, useState } from 'react';

type UseStorageSetter<TValue> = (value: TValue) => void;
type SetStorageCallback = (
  key: string,
  value: unknown,
  category: OneTrustCategory,
  options?: unknown
) => void;
type GetStorageCallback = (key: string) => string | unknown;

export type UseStorageOptions<TOptions, TValue> = {
  key: string;
  initialValue: TValue;
  user?: UserInfo;
  category: OneTrustCategory;
  options?: TOptions;
  setItemToStorage: SetStorageCallback;
  getItemFromStorage: GetStorageCallback;
};

function getItemKey(key: string, user?: UserInfo): string {
  return user ? `${key}_${getStorageKey(user)}` : key;
}

function getStorageKey(user: UserInfo): string {
  const merchantId = user.merchant?.id;
  const personId = user.person?.id;
  return `${personId}_${merchantId}`;
}

function useStorage<TOptions, TValue>(
  useStorageOptions: UseStorageOptions<TOptions, TValue>
): [
  value: TValue,
  setValue: UseStorageSetter<TValue>,
  readFromStorage: boolean
] {
  const { setItemToStorage, getItemFromStorage, key, user, initialValue } =
    useStorageOptions;

  const [value, setValue] = useState<TValue>(initialValue);
  const [readFromStorage, setReadFromStorage] = useState(false);

  useEffect(() => {
    // Only read local storage from the client
    let result;
    const itemKey = getItemKey(key, user);
    const item = getItemFromStorage(itemKey);
    try {
      result =
        typeof item === 'string' ? (JSON.parse(item) as TValue) : initialValue;
      setReadFromStorage(true);
    } catch {
      // Eat the parser error and assume its not JSON format
      result = (item as TValue) ?? initialValue;
    }

    setValue(result);
  }, [
    setValue,
    key,
    user,
    initialValue,
    getItemFromStorage,
    setReadFromStorage,
  ]);

  /**
   * Will set the value to storage based on the given category of use.
   */
  const setItem = (value: TValue): void => {
    const { key, category, user, options } = useStorageOptions;
    const itemKey = getItemKey(key, user);
    let valueToInsert = undefined;
    try {
      valueToInsert = typeof value === 'string' ? value : JSON.stringify(value);
      setItemToStorage(itemKey, valueToInsert, category, options);
      setValue(value);
    } catch (error) {
      const message = error instanceof Error ? error.message : String(error);
      captureException(
        `[StorageHelpers] setItemToStorage - Error serializing value to storage. Error: ${message}`
      );
    }
  };

  return [value, setItem, readFromStorage];
}

export { useStorage, type UseStorageSetter };
