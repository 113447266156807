import {
  setLocalStorageItem,
  getLocalStorageItem,
  OneTrustCategory,
} from '@square/onetrust-compliant-access';

import { useStorage } from './use-storage';

function useLocalStorage<TValue>(options: {
  key: string;
  initialValue: TValue;
  category: OneTrustCategory;
}) {
  return useStorage<never, TValue>({
    ...options,
    setItemToStorage: setLocalStorageItem,
    getItemFromStorage: getLocalStorageItem,
  });
}

export { useLocalStorage };
