import { TestProps } from '@squareup/dex-types-shared-utils';
import { Box, BoxProps } from '@squareup/dex-ui-shared-base';
import React, { FC, PropsWithChildren } from 'react';

import styles from './doc-breadcrumb.module.css';

interface BreadcrumbContainerProps {}

const BreadcrumbContainer: FC<
  PropsWithChildren<
    BreadcrumbContainerProps & TestProps & Pick<BoxProps, 'margin' | 'padding'>
  >
> = ({ children, testId, margin, padding }) => {
  return (
    <Box
      className={styles.container}
      testId={testId}
      margin={margin}
      padding={padding}
    >
      {children}
    </Box>
  );
};

export { BreadcrumbContainer };
