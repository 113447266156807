import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import { getEnvironmentName } from '@squareup/dex-utils-environment';
import { usePathWithoutHash } from '@squareup/dex-utils-shared-routing-hooks';
import clsx from 'clsx';
import React, { FunctionComponent, useCallback, useMemo } from 'react';

import { DynamicSvelteComponent } from '../SvelteComponent';

import styles from './feedback-widget.module.css';

interface YesNoPromptProps {
  componentId: number;
  inline?: boolean;
}

interface YesNoModuleProps {
  subcomponentId: string;
  url: string;
}

function getSvelteComponentProps(props: YesNoPromptProps & YesNoModuleProps) {
  return {
    componentId: props.componentId,
    subcomponentId: props.subcomponentId,
    environment: getEnvironmentName(),
    url: props.subcomponentId,
    inline: props.inline,
  };
}

const ClientSideComponent = import('@squareup/svelte-dex-feedback').then(
  (mod) => {
    return mod.YesNoPrompt;
  }
);

export const YesNoPrompt: FunctionComponent<
  YesNoPromptProps & NullableClassName
> = ({ className, ...rest }) => {
  const pathWithoutHash = usePathWithoutHash();

  const props = useMemo(() => {
    return getSvelteComponentProps({
      ...rest,
      subcomponentId: pathWithoutHash,
      url: pathWithoutHash,
    });
  }, [pathWithoutHash, rest]);

  const Children = useCallback(
    () => (
      <DynamicSvelteComponent
        clientSideComponent={ClientSideComponent}
        componentProps={props}
      ></DynamicSvelteComponent>
    ),
    [props]
  );

  const result = useMemo(() => {
    // Svelte components do not accept classnames so for now add a div when needed.
    return (
      <Box
        className={clsx(rest.inline ? styles.inline : styles.large, className)}
      >
        <Children />
      </Box>
    );
  }, [Children, className, rest.inline]);

  return result;
};
