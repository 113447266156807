import {
  MarketListCustomEvent,
  MarketTextareaCustomEvent,
} from '@market/web-components';
import { TMarketListSelectionsDidChangeEventDetail } from '@market/web-components/dist/types/components/market-list/events';
import { Markdown } from '@squareup/dex-feature-markdown';
import {
  Question,
  Option,
  SelectedOptionsState,
  ShortAnswersState,
} from '@squareup/dex-types-shared-app-launch';
import { Box } from '@squareup/dex-ui-shared-base';
import {
  MarketCheckbox,
  MarketList,
  MarketRadio,
  MarketRow,
  MarketTextarea,
} from '@squareup/dex-ui-shared-market';
import { publishStateAction } from '@squareup/dex-utils-application-behavior-events';
import { stripMarkdown } from '@squareup/dex-utils-text';
import clsx from 'clsx';
import React, {
  CSSProperties,
  useCallback,
  useId,
  useMemo,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import styles from './requirement-question.module.css';

interface RequirementQuestionProps {
  question: Question;
  selectedOptions: SelectedOptionsState;
  shortAnswers: ShortAnswersState;
  domainName: string;
  onOptionSelected(option: Option, parentQuestion: Question): void;
  onShortAnswerChange(newValue: string, parentQuestion: Question): void;
  trackingId: string;
  questionsRefs?:
    | React.MutableRefObject<Map<string, HTMLElement | null>>
    | undefined;
  showEditLink?: boolean | undefined;
}

export function RequirementQuestion({
  question,
  selectedOptions,
  shortAnswers,
  domainName,
  onOptionSelected,
  onShortAnswerChange,
  trackingId,
  questionsRefs,
  showEditLink = false,
}: RequirementQuestionProps) {
  const questionLabelId = useId();

  const [shortAnswer, setShortAnswer] = useState<string>(
    shortAnswers.get(question.id) || ''
  );

  const optionIdMap: Map<string, string> = useMemo(() => {
    const optionMap = new Map<string, string>();
    for (const option of question.options || []) {
      optionMap.set(option.id, option.text);
    }
    return optionMap;
  }, [question.options]);

  const selectedValues = useMemo<string>(
    () =>
      question.options
        ?.filter((o) => selectedOptions.has(o.id))
        .map((o) => o.id)
        .join(',') || '',
    [selectedOptions, question]
  );
  const isMultiSelect = useMemo<boolean>(
    () => question.type === 'MultiSelect',
    [question]
  );
  const isShortAnswerQuestion = useMemo<boolean>(
    () => question.type === 'ShortAnswer',
    [question]
  );

  const logMetricOnOptionSelected = useCallback(
    (detail: TMarketListSelectionsDidChangeEventDetail) => {
      publishStateAction({
        action: 'question-answered',
        onType: trackingId,
        onIdentifier: question.text,
        extra: JSON.stringify({
          domain: domainName,
          type: question.type,
          selectedOptions: [...detail.currentSelectionValues].map((id) =>
            optionIdMap.get(id)
          ),
        }),
      });
    },
    [trackingId, question.text, question.type, domainName, optionIdMap]
  );

  const logMetricOnShortAnswerChange = useCallback(
    (value: string) => {
      publishStateAction({
        action: 'question-answered',
        onType: trackingId,
        onIdentifier: question.text,
        extra: JSON.stringify({
          domain: domainName,
          type: question.type,
          shortAnswer: value,
        }),
      });
    },
    [trackingId, question.text, question.type, domainName]
  );

  const handleSelectionDidChange = useCallback(
    ({
      detail,
    }: MarketListCustomEvent<TMarketListSelectionsDidChangeEventDetail>): void => {
      const { newDeselection, newDeselectionValue, newSelectionValue } = detail;

      const selectedOptionId: string = newDeselection
        ? newDeselectionValue
        : newSelectionValue;
      const selectedOption = question.options?.find(
        (option) => option.id === selectedOptionId
      );

      selectedOption && onOptionSelected(selectedOption, question);

      logMetricOnOptionSelected(detail);
    },
    [question, onOptionSelected, logMetricOnOptionSelected]
  );

  const DEBOUNCE_WAIT_MS = 100;
  const debouncedOnShortAnswerChange = useDebouncedCallback(
    (value, question) => {
      onShortAnswerChange(value, question);
      logMetricOnShortAnswerChange(value);
    },
    DEBOUNCE_WAIT_MS
  );

  const handleTextAreaValueChange = useCallback(
    ({
      detail,
    }: MarketTextareaCustomEvent<{
      value: string;
      originalEvent: KeyboardEvent;
    }>): void => {
      setShortAnswer(detail.value);
      debouncedOnShortAnswerChange(detail.value, question);
    },
    [question, debouncedOnShortAnswerChange]
  );

  // If questionRefs are empty then it is the first render and we don't need to add the fade in.
  // If this isn't the first render and the question has yet to be mounted we want to add the fade in animation.
  const addFadeIn = useMemo(() => {
    return !questionsRefs?.current.has(question.id);
  }, [question.id, questionsRefs]);

  const updateQuestionRefs = useCallback(
    (ele: HTMLElement | undefined) => {
      if (ele) {
        addFadeIn && ele.classList.add(styles['new-item'] || '');
        // On mount collect add question element to map
        questionsRefs?.current.set(question.id, ele);
      } else {
        // On unmount remove question element from map
        questionsRefs?.current.delete(question.id);
      }
    },
    [addFadeIn, question.id, questionsRefs]
  );

  const contentfulEditHref = `https://app.contentful.com/spaces/1nw4q0oohfju/entries/${question.id}`;

  return (
    <Box
      testId="requirement-question"
      className={clsx(styles['list'])}
      // Pass in the number of market rows as a CSS variable, because the rows
      // will disappear from the DOM right before the market-list it hydrates
      // We need to be able to give the market-list a min-height to avoid layout shift
      style={
        {
          '--list-num-rows': `${question.options?.length || 0}`,
        } as CSSProperties
      }
      ref={updateQuestionRefs}
    >
      <Box margin={{ top: '3x', bottom: '1x' }} id={questionLabelId}>
        <Markdown markdown={question.text} textWeight="medium" />
      </Box>
      {isShortAnswerQuestion ? (
        <MarketTextarea
          data-testid={`short-answer-text-area-${question.id}`}
          value={shortAnswer}
          onMarketTextareaValueChange={handleTextAreaValueChange}
          className={clsx(styles['short-answer-textarea'])}
        />
      ) : (
        <MarketList
          aria-labelledby={questionLabelId}
          data-testid="requirement-options-list"
          onMarketListSelectionsDidChange={handleSelectionDidChange}
          value={selectedValues}
          multiselect={isMultiSelect}
          interactive
        >
          {question.options?.map((option) => {
            const nameAttr = stripMarkdown(option.text);

            return (
              <MarketRow
                testId={`${option.id}-option`}
                trackingId={`app_launch_option_${option.id}_row`}
                className={clsx(styles['option-row'])}
                key={option.id}
                value={option.id}
                size="small"
                controlPosition="leading"
              >
                <Box slot="label" as="label">
                  <Markdown markdown={option.text} />
                </Box>
                {isMultiSelect ? (
                  <MarketCheckbox
                    // There's a bug where the checkbox and radio buttons don't get the
                    // label for the aria-label properly.
                    // A workaround is to instead set the `name` field, which will pass
                    // the aria-label to the <input> properly.
                    // See: https://github.com/squareup/market/issues/7475
                    {...{ name: nameAttr }}
                    slot="control"
                    data-testid={`${option.id}-option-checkbox`}
                  />
                ) : (
                  <MarketRadio
                    // There's a bug where the checkbox and radio buttons don't get the
                    // label for the aria-label properly.
                    // A workaround is to instead set the `name` field, which will pass
                    // the aria-label to the <input> properly.
                    // See: https://github.com/squareup/market/issues/7475
                    {...{ name: nameAttr }}
                    slot="control"
                    data-testid={`${option.id}-option-radio`}
                  />
                )}
              </MarketRow>
            );
          })}
        </MarketList>
      )}
      {showEditLink && (
        <Box margin={{ top: '0.5x' }}>
          <a href={contentfulEditHref} target="_blank" rel="noreferrer">
            edit in contentful
          </a>
        </Box>
      )}
    </Box>
  );
}
