import type { JSX } from '@market/web-components';

export interface Toast {
  isPersistent?: boolean;
  message: string;
  variant?: JSX.MarketToast['variant'];
}

export const createMarketToastElement = ({
  isPersistent = false,
  message,
  variant = 'info',
}: Toast) => {
  const toastElement: globalThis.HTMLMarketToastElement =
    document.createElement('market-toast');
  toastElement.persistent = isPersistent;
  toastElement.textContent = message;
  toastElement.variant = variant;

  return toastElement;
};
