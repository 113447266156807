import * as React from 'react';
import { SVGProps } from 'react';

const SvgHome = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={10}
    height={20}
    viewBox="0 0 10 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.5 16a.5.5 0 0 1-.5-.5V9.02a1 1 0 0 1 .427-.819l4-2.8a1 1 0 0 1 1.146 0l4 2.8a1 1 0 0 1 .427.82V15.5a.5.5 0 0 1-.5.5H6v-4H4v4H.5Z"
      fill="#000"
      fillOpacity={0.55}
    />
  </svg>
);
export default SvgHome;
