// eslint-disable-next-line no-restricted-imports
import { MarketPill as MarketPillBinding } from '@market/react';
import { MarketBindingWithoutClassName } from '@squareup/dex-types-shared-ui';
import { TestProps } from '@squareup/dex-types-shared-utils';
import clsx from 'clsx';
import React from 'react';

import styles from './market.module.css';

type BasePillProps = MarketBindingWithoutClassName<
  React.ComponentProps<typeof MarketPillBinding>
>;

// Extending this because DEX requires a specific "orange" variant
// which doesn't exist on the native MarketPill
type MarketPillVariant = BasePillProps['variant'] | 'orange';

type PillProps = Omit<
  MarketBindingWithoutClassName<React.ComponentProps<typeof MarketPillBinding>>,
  'variant'
> & { variant: MarketPillVariant } & TestProps;

const MarketPill = React.forwardRef<
  globalThis.HTMLMarketPillElement,
  PillProps
>(({ children, testId, className, variant, ...rest }, ref) => {
  let extraClassName;
  if (variant === 'orange') {
    extraClassName = styles['market-pill-orange'];
    // eslint-disable-next-line no-param-reassign
    variant = 'warning';
  }

  return (
    <MarketPillBinding
      {...rest}
      {...(variant && { variant })}
      ref={ref}
      data-testid={testId}
      className={clsx(extraClassName && extraClassName, className)}
    >
      {children}
    </MarketPillBinding>
  );
});

MarketPill.displayName = 'MarketPill';

export { MarketPill, type MarketPillVariant };
