import { getEnvironmentName } from '@squareup/dex-utils-environment';

const APP_NAME = 'dex-tech-fe';

async function setupServerMetrics() {
  const { ServerMetricsClient } = await import(
    '@squareup/dex-utils-shared-server-metrics-client'
  );
  const serverMetricsClient = new ServerMetricsClient(
    APP_NAME,
    getEnvironmentName()
  );
  globalThis.metricsClient = serverMetricsClient;
}

async function setupS2sFetch() {
  const { s2sConnector } = await import(
    '@squareup/dex-utils-shared-s2s-connector'
  );

  globalThis.s2sConnector = s2sConnector;
}

async function setupServerLogger() {
  const { ServerLogger } = await import(
    '@squareup/dex-utils-shared-server-logger'
  );

  globalThis.serverLogger = new ServerLogger();
}

export { setupServerMetrics, setupS2sFetch, setupServerLogger };
