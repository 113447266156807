import {
  getNextPublicSquareURL,
  isDevelopmentEnvironment,
} from '@squareup/dex-utils-environment';
import { v4 } from 'uuid';

const savtCookieName = '_savt';
const secondsInYear = 60 * 60 * 24 * 365;

const createSavtCookie = () => {
  const uuid = v4();

  // For staging and prod, the savt takes up the whole domain at `.squareup.com`
  const domainUrl = !isDevelopmentEnvironment()
    ? `.${new URL(getNextPublicSquareURL()).hostname}`
    : '';
  return {
    name: savtCookieName,
    value: uuid,
    maxAge: 3 * secondsInYear,
    secure: true,
    path: '/',
    domain: domainUrl,
  };
};

export { savtCookieName, createSavtCookie };
