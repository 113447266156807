import { MarketListCustomEvent } from '@market/web-components';
import { TMarketListSelectionsDidChangeEventDetail } from '@market/web-components/dist/types/components/market-list/events';
import { Markdown } from '@squareup/dex-feature-markdown';
import { Spinner } from '@squareup/dex-icons/dex/misc';
import SvgDownload from '@squareup/dex-icons/market/action/Download';
import { Requirement } from '@squareup/dex-types-shared-app-launch';
import { Link, NativeButton, StickyContainer } from '@squareup/dex-ui';
import {
  Box,
  Heading20,
  Paragraph20,
  Paragraph30,
} from '@squareup/dex-ui-shared-base';
import { downloadTextFile } from '@squareup/dex-ui-shared-download-text-file';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import {
  MarketCheckbox,
  MarketList,
  MarketRow,
} from '@squareup/dex-ui-shared-market';
import { publishStateAction } from '@squareup/dex-utils-application-behavior-events';
import { stripMarkdown } from '@squareup/dex-utils-text';
import clsx from 'clsx';
import React, { useCallback, useMemo, useRef } from 'react';

import styles from './requirements-list.module.css';

interface RequirementsListProps {
  domainName: string;
  isLoading: boolean;
  onRequirementSelected: (selectedId: string) => void;
  requirements: Requirement[];
  selectedRequirements: Set<string>;
}

export function RequirementsList({
  domainName,
  isLoading,
  onRequirementSelected,
  requirements,
  selectedRequirements,
}: RequirementsListProps) {
  const scrollAreaRef = useRef<HTMLElement>(null);

  const filename = useMemo(() => {
    const parts = domainName
      .toLowerCase()
      .split(/[\s-]/)
      .filter((p) => p);
    if (!parts.includes('requirements')) {
      parts.push('requirements');
    }
    return `${parts.join('-')}.md`;
  }, [domainName]);

  const requirementIdMap: Map<string, string> = useMemo(() => {
    const reqMap = new Map<string, string>();
    for (const requirement of requirements) {
      reqMap.set(requirement.id, requirement.text);
    }
    return reqMap;
  }, [requirements]);

  const downloadHandler = useCallback(() => {
    let markdownString = '';
    requirements.forEach((requirement) => {
      const checkbox = selectedRequirements.has(requirement.id) ? '[x]' : '[ ]';
      markdownString += `- ${checkbox} ${requirement.text}\n\n`;
    });
    downloadTextFile(markdownString, filename);
  }, [requirements, selectedRequirements, filename]);

  const onSelected = useCallback(
    (
      event: MarketListCustomEvent<TMarketListSelectionsDidChangeEventDetail>
    ) => {
      const { newSelectionValue, newDeselectionValue } = event.detail;
      onRequirementSelected(newSelectionValue || newDeselectionValue);

      if (newSelectionValue || newDeselectionValue) {
        publishStateAction({
          action: 'app_launch_req',
          onIdentifier: newSelectionValue ? 'req_select' : 'req_deselect',
          extra: JSON.stringify({
            domain: domainName,
            req: requirementIdMap.get(newSelectionValue || newDeselectionValue),
          }),
        });
      }
    },
    [domainName, requirementIdMap, onRequirementSelected]
  );

  const requirementsHeader = (
    <Box
      testId="requirements-header"
      className={styles['requirements-header']}
      padding={{ horizontal: '3x', vertical: '2x' }}
      border={{
        line: { top: 'standard', right: 'standard', left: 'standard' },
        radius: { topLeft: 'standard', topRight: 'standard' },
      }}
    >
      <Paragraph20 weight="medium">Generated requirements</Paragraph20>
      <NativeButton
        aria-label="Download requirements"
        testId="download-requirements"
        trackingId="app_launch_req_doc_download"
        trackingExtra={JSON.stringify({
          selectedRequirements: [...selectedRequirements].map((reqId) =>
            requirementIdMap.get(reqId)
          ),
        })}
        onClick={downloadHandler}
        disabled={requirements.length === 0}
      >
        <SvgDownload
          className={
            requirements.length === 0
              ? commonIconStyles['icon-disabled-color-20']
              : commonIconStyles['icon-color']
          }
        />
      </NativeButton>
    </Box>
  );

  const requirementsContent =
    requirements.length === 0 ? (
      <Box
        testId="no-requirements-text"
        padding={{ horizontal: '1x', vertical: '6x' }}
        className={clsx(styles['no-requirements-text'])}
      >
        <Heading20 margin={{ bottom: '1x' }}>
          Generate your requirements
        </Heading20>
        <Paragraph30>
          As you answer the questions about your API usage, your personalized
          requirements will appear here
        </Paragraph30>
      </Box>
    ) : (
      <MarketList
        onMarketListSelectionsDidChange={onSelected}
        value={[...selectedRequirements]}
        multiselect={true}
        interactive={true}
        aria-label="Generated requirements list"
      >
        {requirements?.map((req) => {
          return (
            <MarketRow
              size="small"
              className={clsx(styles['row'])}
              testId={req.id}
              trackingId={`app_launch_req_${req.id}__row`}
              key={req.id}
              value={req.id}
            >
              <Box
                className={clsx(styles['requirement-row-label'])}
                slot="label"
                as="label"
              >
                <Markdown markdown={req.text} textVariant="20" />
              </Box>
              <Link
                trackingId="app_launch_req_details_link"
                href={req.documentationUrl || '/'}
                passHref
                omitAnchor={true}
              >
                <Box
                  slot="side-label"
                  as="a"
                  trackingId="app_launch_req_details_click"
                  trackingExtra={req.documentationUrl}
                  className={clsx(
                    req.documentationUrl
                      ? styles['side-label']
                      : styles['hidden-side-label']
                  )}
                  target="_blank"
                >
                  Details
                </Box>
              </Link>
              <MarketCheckbox
                // There's a bug where the checkbox and radio buttons don't get the
                // label for the aria-label properly.
                // A workaround is to instead set the `name` field, which will pass
                // the aria-label to the <input> properly.
                // See: https://github.com/squareup/market/issues/7475
                {...{ name: stripMarkdown(req.text) }}
                slot="control"
                data-testid="requirement-row-checkbox"
              />
            </MarketRow>
          );
        })}
      </MarketList>
    );

  const requirementsLoadingContent = (
    <Box className={styles['spinner-container']}>
      <Spinner
        className={clsx(commonIconStyles['icon-spinner'], styles['spinner'])}
      />
    </Box>
  );

  return (
    <StickyContainer
      scrollableRef={scrollAreaRef}
      className={clsx(styles['requirements-list'])}
      testId="requirements-list"
    >
      {requirementsHeader}
      <Box
        padding={{ top: '2x', bottom: '2x' }}
        className={clsx(styles['scrollable-el'])}
        border={{
          line: 'standard',
          radius: {
            bottomLeft: 'standard',
            bottomRight: 'standard',
          },
        }}
        ref={scrollAreaRef}
      >
        {isLoading ? requirementsLoadingContent : requirementsContent}
      </Box>
    </StickyContainer>
  );
}
