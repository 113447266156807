import LeftArrow from '@squareup/dex-icons/market/arrow/Left';
import RightArrow from '@squareup/dex-icons/market/arrow/Right';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { TestProps, TrackedProps } from '@squareup/dex-types-shared-utils';
import { Box, Paragraph20, Paragraph30 } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { makeDocUrlRelative } from '@squareup/dex-utils-docs-navigation';
import clsx from 'clsx';
import React, { FC } from 'react';

import { Link } from '../Link';

import styles from './next-step-block.module.css';

interface NextStepAction {
  title: string;
  url: string;
}

interface NextStepBlockProps {
  previous?: NextStepAction | undefined;
  next?: NextStepAction | undefined;
}

const StepBlock: FC<
  NextStepAction & { direction: 'left' | 'right' } & NullableClassName &
    TestProps &
    TrackedProps
> = ({ title, url, direction, className, testId, trackingId }) => {
  const subtitle = direction === 'left' ? 'Previous' : 'Next';
  return (
    <Box className={className} testId={testId}>
      <Paragraph20
        colorVariant="20"
        padding={{ horizontal: '3x' }}
        className={clsx(direction === 'right' && styles.end)}
      >
        {subtitle}
      </Paragraph20>
      <Box className={styles['url-row']}>
        {direction === 'left' && (
          <LeftArrow
            className={clsx(
              commonIconStyles['icon-fill-current-color'],
              styles.arrow
            )}
          />
        )}
        <Link href={url} passHref trackingId={trackingId} omitAnchor={true}>
          <Paragraph30 as="a" weight="semi-bold" className={styles.anchor}>
            {title}
          </Paragraph30>
        </Link>
        {direction === 'right' && (
          <RightArrow
            className={clsx(
              commonIconStyles['icon-fill-current-color'],
              styles.arrow
            )}
          />
        )}
      </Box>
    </Box>
  );
};

const NextStepBlock: FC<NextStepBlockProps> = ({ previous, next }) => {
  return (
    <Box className={styles.block}>
      {previous ? (
        <StepBlock
          testId={'previous-step-block'}
          trackingId={'previous-step-block'}
          title={previous.title}
          url={makeDocUrlRelative(previous.url)}
          direction={'left'}
        />
      ) : (
        <Box />
      )}
      {next ? (
        <StepBlock
          testId={'next-step-block'}
          trackingId={'next-step-block'}
          title={next.title}
          url={makeDocUrlRelative(next.url)}
          direction={'right'}
        />
      ) : (
        <Box />
      )}
    </Box>
  );
};

export { NextStepBlock };
