export function downloadTextFile(fileTextContent: string, filename: string) {
  const f = new File([fileTextContent], filename);
  const downloadUrl = URL.createObjectURL(f);
  const element = document.createElement('a');
  element.setAttribute('href', downloadUrl);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
  URL.revokeObjectURL(downloadUrl);
}
