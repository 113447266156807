import { OneTrustCategory } from '@square/onetrust-compliant-access';
import {
  SavedSelectedOptions,
  SavedShortAnswers,
  SelectedOptionsState,
  ShortAnswersState,
  SavedSelectedRequirements,
  SelectedRequirementsState,
} from '@squareup/dex-types-shared-app-launch';
import { useLocalStorage } from '@squareup/dex-ui-hooks';

/**
 * Reads from and saves requirement doc answers to local storage in the browser by domainId.
 * @param domainId
 */
export function useSavedAnswers(domainId: string) {
  const [savedSelectedOptions, _saveSelectedOptions, optionsReadFromStorage] =
    useLocalStorage<SavedSelectedOptions>({
      key: `req-domain-${domainId}-selected-options-v2`,
      initialValue: null,
      category: OneTrustCategory.FUNCTIONAL,
    });

  const [savedShortAnswers, _saveShortAnswers, shortAnsReadFromStorage] =
    useLocalStorage<SavedShortAnswers>({
      key: `req-domain-${domainId}-short-answers`,
      initialValue: null,
      category: OneTrustCategory.FUNCTIONAL,
    });

  const [
    savedSelectedRequirements,
    _saveSelectedRequirements,
    requirementsReadFromStorage,
  ] = useLocalStorage<SavedSelectedRequirements>({
    key: `req-domain-${domainId}-requirements`,
    initialValue: null,
    category: OneTrustCategory.FUNCTIONAL,
  });

  // converts selected options map to object for json serialization
  const saveSelectedOptions = (selectedOptions: SelectedOptionsState) => {
    _saveSelectedOptions([...selectedOptions]);
  };

  // converts short answer map to object for json serialization
  const saveShortAnswers = (shortAnswers: ShortAnswersState) => {
    _saveShortAnswers(Object.fromEntries(shortAnswers));
  };

  // converts selected requirements set to array for json serialization
  const saveSelectedRequirements = (
    selectedRequirements: SelectedRequirementsState
  ) => {
    _saveSelectedRequirements([...selectedRequirements]);
  };

  return {
    savedSelectedOptions,
    savedShortAnswers,
    savedSelectedRequirements,
    saveShortAnswers,
    saveSelectedOptions,
    saveSelectedRequirements,
    readFromStorage:
      optionsReadFromStorage &&
      shortAnsReadFromStorage &&
      requirementsReadFromStorage,
  };
}
