import { isServerContext } from '@squareup/dex-utils-environment';
import { log } from '@squareup/dex-utils-shared-logging';

interface FrameworkMetricsRouteOptions {
  group?: string | undefined;
  handler?: string | undefined;
}

interface FuncResponseWithCode<T> {
  responseCode: number;
  returnVal: T;
}

/**
 * We don't use the default NSC metrics right now, because we didn't want to use a custom server.
 * However, we still want metrics when we hit our server routes. To solve it, this helper will
 * record this information in the same way NSC does.
 * See: https://github.com/squareup/node-service-container/blob/main/packages/container/src/-private/middleware/reporting.ts#L50
 * And: https://github.com/squareup/node-service-container/blob/main/packages/container/src/-private/middleware/reporting.ts#L77
 *
 * We can get rid of this whenever we decide to use a customer server properly
 * @param options Optional group and handler options for your metrics.
 * @param func The function that runs the logic to determine the response code
 * @returns The return value of the func
 */
export async function runAndRecordFrameworkMetrics<FuncReturnVal>(
  { group = '', handler = '' }: FrameworkMetricsRouteOptions,
  func: () => Promise<FuncResponseWithCode<FuncReturnVal>>
): Promise<FuncReturnVal> {
  const startTime = Date.now();
  try {
    const { responseCode, returnVal } = await func();

    if (!isServerContext() || !globalThis.metricsClient) {
      return returnVal;
    }

    globalThis.metricsClient.counter('framework.http.response_codes', 1, {
      aggregateHost: true,
      tags: {
        code: `${Math.floor(responseCode / 100)}00`,
        group,
        handler,
      },
    });

    globalThis.metricsClient.counter('framework.http.exact_response_codes', 1, {
      tags: {
        code: responseCode.toString(),
        group,
        handler,
      },
    });

    log.info(
      `http response for group: ${group} - ${handler} - ${responseCode}`
    );

    return returnVal;
  } finally {
    const duration = Date.now() - startTime;
    if (isServerContext()) {
      globalThis?.metricsClient?.histogram(
        'framework.http.response_times',
        duration,
        {
          aggregateHost: true,
          tags: {
            group,
            handler,
          },
        }
      );
    }
  }
}
