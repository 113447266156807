import { Markdown } from '@squareup/dex-feature-markdown';
import { YesNoPrompt } from '@squareup/dex-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import { ComponentIds } from '@squareup/svelte-dex-feedback';
import React, { FC } from 'react';

import styles from './doc-feedback-prompt.module.css';

const supportMarkdown =
  'If you need more assistance, contact [Developer and App Marketplace Support](https://squareup.com/help/contact?panel=BF53A9C8EF68) or ask for help in the [Developer Forums](https://developer.squareup.com/forums).';

const DocFeedbackPrompt: FC = () => {
  return (
    <Box
      testId="docs-feedback-prompt"
      className={styles.container}
      padding={{ top: '8x', bottom: '2x' }}
      border={{ line: { top: '10' } }}
    >
      <Box margin={{ bottom: '2x' }}>
        <YesNoPrompt componentId={ComponentIds.longFormDocs} inline={false} />
      </Box>
      <Markdown markdown={supportMarkdown} />
    </Box>
  );
};

export { DocFeedbackPrompt };
