import {
  publishSystemTrace,
  publishSystemError,
} from '@squareup/dex-utils-application-behavior-events';

function debug(
  message: string,
  context?: Record<string, string> | undefined
): void {
  publishSystemTrace(
    {
      message,
      context,
    },
    'debug'
  );
}

function info(
  message: string,
  context?: Record<string, string> | undefined
): void {
  publishSystemTrace({
    message,
    context,
  });
}

function warn(
  message: string,
  context?: Record<string, string> | undefined
): void {
  publishSystemTrace(
    {
      message,
      context,
    },
    'warn'
  );
}

function error(
  message: string,
  context?: Record<string, string> | undefined,
  error?: Error | undefined
): void {
  publishSystemError({
    message,
    context,
    error,
  });
}

const log = {
  debug,
  info,
  warn,
  error,
};

export { log };
