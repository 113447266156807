import { publishUnhandledError } from '@squareup/dex-utils-application-behavior-events';
import {
  getServerDataApiEndpoint,
  isServerContext,
} from '@squareup/dex-utils-environment';

// See: https://zeroheight.com/55c402603/p/61d3c0-data-api-reference/b/88933c
interface SharedTrackOpts {
  entityType: string;
  eventName: string;
  properties?: Record<string, string | number>;
}

type TrackOpts =
  | ({
      entityId?: string;
      anonymousId: string;
    } & SharedTrackOpts)
  | ({
      entityId: string;
      anonymousId?: string;
    } & SharedTrackOpts);

/**
 * CDP does not have a nodejs library, so we need to hit their APIs ourselves
 */
class ServerDataApi {
  endpoint: string;
  keyBase64: string;
  constructor(private key: string) {
    this.endpoint = getServerDataApiEndpoint();
    this.keyBase64 = Buffer.from(`${this.key}:`).toString('base64');
  }

  async track(options: TrackOpts): Promise<void> {
    // Note that you must set up the s2s connector beforehand, otherwise this will fail
    if (!isServerContext() || !globalThis.s2sConnector) {
      return;
    }

    const headers = {
      Authorization: `Basic ${this.keyBase64}`,
    };

    const metricsClient = globalThis.metricsClient;
    const startTime = Date.now();

    try {
      const response = await globalThis.s2sConnector.fetch(
        this.endpoint,
        'cdp-ingest',
        {
          headers,
          method: 'POST',
          body: JSON.stringify({
            batch: [
              {
                ...options,
                apiMethod: 'track',
                apiKey: this.key,
              },
            ],
          }),
        }
      );

      metricsClient?.emitHttpStatusMetric(
        this.endpoint,
        'POST',
        response.status,
        'cdp-ingest'
      );

      if (!response.ok) {
        const body = await response.text();
        publishUnhandledError(
          new Error(
            `Failed to log data-api track call: ${response.status} - ${body}`
          )
        );
      }
    } catch (error) {
      metricsClient?.emitHttpStatusMetric(
        this.endpoint,
        'POST',
        -1,
        'cdp-ingest'
      );

      publishUnhandledError(error as Error);
    } finally {
      const duration = Date.now() - startTime;

      if (metricsClient) {
        metricsClient?.emitHttpLatencyMetric(
          this.endpoint,
          'POST',
          duration,
          'cdp-ingest'
        );
      }
    }
  }
}

export { ServerDataApi };
