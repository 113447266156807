import { Ellipse } from '@squareup/dex-icons/dex/misc';
import { CheckmarkCircle } from '@squareup/dex-icons/market/alert';
import { SectionCompletion, TocSection } from '@squareup/dex-types-shared-docs';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import {
  shimStyles,
  SidebarLink20,
  SidebarLink30,
  SidebarLinkIcon,
  StickyContainer,
} from '@squareup/dex-ui';
import {
  Box,
  Heading5,
  Paragraph30,
  Spacing,
} from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, {
  FC,
  PropsWithChildren,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { observe } from 'react-intersection-observer';

import styles from './floating-toc.module.css';

interface AppSubmissionFloatingTocProps {
  headerTitle: string;
  sections: Array<SectionCompletion>;
  mainContentRef: RefObject<HTMLElement>;
  withProgressIcons?: boolean;
}

interface FloatingTocProps {
  headerTitle: string;
  sections: Array<TocSection>;
  mainContentRef: RefObject<HTMLElement>;
}

/**
 * Gets the largest item in a map
 * @param map
 * @returns The largest item in the map, or undefined
 */
function getLargest(map: Map<string, number>): number | undefined {
  if (map.size === 0) {
    return undefined;
  }

  const values = [...map.values()];

  return Math.max(...values);
}

type FtocVariants = { ftocVariant: 'docs' | 'app-submission' };

/**
 * The Floating TOC's job is to be a sticky TOC that highlights
 * the link of the section you are currently looking at.
 */
const FloatingToc: FC<
  PropsWithChildren<
    AppSubmissionFloatingTocProps & NullableClassName & FtocVariants
  >
> = ({
  headerTitle,
  sections,
  mainContentRef,
  withProgressIcons,
  className,
  ftocVariant,
}) => {
  const ftocScrollAreaRef = useRef<HTMLElement>(null);

  // Keep this as a ref to avoid re-rendering
  const inViewMap = useRef<Map<string, number>>(new Map());
  const [hasShim, setHasShim] = useState(false);

  useEffect(() => {
    if (!mainContentRef.current) {
      return () => null;
    }

    let anchors: HTMLAnchorElement[] = [];
    let cleanupObservers: Array<() => void> = [];

    // delay highlight setup until after tab transition
    const timeoutId = setTimeout(() => {
      // eslint-disable-next-line unicorn/prefer-spread
      anchors = Array.from(
        ftocScrollAreaRef.current?.querySelectorAll('a') || []
      );
      cleanupObservers = [];

      handleHighlights();
      handleScrollBottom();
    }, 500);

    function handleHighlights() {
      const elements = sections
        .map((section) => {
          try {
            // If we fail to run the query selector due to a bad id,
            // avoid crashing the whole page and instead eat the error.
            return mainContentRef.current?.querySelector(
              `[id='${section.id}']`
            );
          } catch {
            return undefined;
          }
        })
        .filter((element): element is Element => Boolean(element));

      // When we intersect anchors, we always mark them as scrolled past
      // Then, we find the last one we scrolled past, and highlight it
      const onIntersect = (
        elementInView: boolean,
        entry: IntersectionObserverEntry,
        index: number
      ) => {
        if (elementInView) {
          inViewMap.current.set(entry.target.id, index);
        } else {
          inViewMap.current.delete(entry.target.id);
        }

        const max = getLargest(inViewMap.current);

        // To avoid lots of re-renders, we will instead add the class-list
        // using normal DOM APIs
        // The logic here is that we'll iterate over all anchors, turning off
        // the selected state unless it's the largest index we've scrolled past, indicating
        // that it's closer to the bottom. In other words, the last item we scrolled past that is
        // closest to the bottom is selected
        for (const [anchorIndex, anchor] of anchors.entries()) {
          if (anchorIndex === max) {
            anchor?.classList.add(styles.selected as string);
          } else {
            anchor?.classList.remove(styles.selected as string);
          }
        }
      };

      for (let i = 0; i < elements.length; i++) {
        // Store to avoid closure issues
        const index = i;
        const element = elements[index];
        const root =
          ftocVariant === 'app-submission'
            ? document.querySelector('main')
            : null;
        const cleanup = observe(
          element as Element,
          (elementInView, entry) => onIntersect(elementInView, entry, index),
          // The top expands upwards forever, which means we're always intersecting once we pass the anchor
          // The bottom contracts by 90%, meaning it's only intersecting once it's almost all the way up your screen
          { rootMargin: '10000000px 0px -90% 0px', root }
        );

        cleanupObservers.push(cleanup);
      }
    }

    function handleScrollBottom() {
      const lastAnchor = anchors?.[anchors?.length - 1];
      if (!lastAnchor) {
        return;
      }

      const cleanup = observe(lastAnchor, (inView) => setHasShim(!inView));

      cleanupObservers.push(cleanup);
    }

    return () => {
      clearTimeout(timeoutId);
      cleanupObservers.forEach((cleanup) => cleanup());
    };
  }, [mainContentRef, sections, inViewMap, ftocVariant, setHasShim]);

  if (sections.length === 0) {
    return null;
  }

  const lastSelected = getLargest(inViewMap.current);

  const docsStickyContainer =
    ftocVariant === 'docs' ? (
      <StickyContainer
        scrollableRef={ftocScrollAreaRef}
        className={clsx(styles['docs-floating-toc'], className)}
        testId={'ftoc'}
      >
        <Heading5 testId="docs-heading">{headerTitle}</Heading5>
        <Box
          ref={ftocScrollAreaRef}
          margin={{ vertical: '1x' }}
          className={clsx(
            styles['docs-scrollable-el'],
            hasShim && shimStyles.shim
          )}
          testId={'ftoc-scroll'}
        >
          {sections.map((section, index) => {
            return (
              <SidebarLink20
                className={clsx(
                  styles['docs-item'],
                  lastSelected === index && styles.selected
                )}
                key={`${section.name}_${index}`}
                href={`#${section.id}`}
                trackingId={'floating-toc-item'}
                trackingExtra={JSON.stringify({ title: section.name })}
                margin={{ bottom: '1x' }}
              >
                {section.name}
              </SidebarLink20>
            );
          })}
        </Box>
      </StickyContainer>
    ) : null;

  const appSubmissionStickyContainer =
    ftocVariant === 'app-submission' ? (
      <StickyContainer
        scrollableRef={ftocScrollAreaRef}
        className={clsx(styles['app-submission-floating-toc'], className)}
        testId="ftoc"
      >
        <Paragraph30 weight="medium" testId="app-submission-heading">
          {headerTitle}
        </Paragraph30>
        <Box
          ref={ftocScrollAreaRef}
          margin={{ bottom: '1x', top: '1.5x' }}
          className={clsx(
            styles['modal-scrollable-el'],
            hasShim && shimStyles.shim
          )}
          testId={'ftoc-scroll'}
        >
          {sections.map((section, index) => {
            const props = {
              className: clsx(
                styles['app-submission-item'],
                lastSelected === index && styles.selected
              ),
              href: `#${section.id}`,
              testId: section.id,
              trackingId: 'floating-toc-item',
              trackingExtra: JSON.stringify({ title: section.name }),
              margin: { bottom: '1.5x' as Spacing },
            };
            return withProgressIcons ? (
              <SidebarLinkIcon
                key={`${section.name}_${index}`}
                {...props}
                icon={
                  section.completed ? (
                    <CheckmarkCircle
                      data-testid="completed-icon"
                      id="completed-icon"
                      className={clsx(styles['completed-icon'])}
                    />
                  ) : (
                    <Ellipse
                      id="incomplete-icon"
                      data-testid="incomplete-icon"
                      className={styles['incomplete-icon']}
                    />
                  )
                }
              >
                {section.name}
              </SidebarLinkIcon>
            ) : (
              <SidebarLink30 key={`${section.name}_${index}`} {...props}>
                {section.name}
              </SidebarLink30>
            );
          })}
        </Box>
      </StickyContainer>
    ) : null;

  return docsStickyContainer || appSubmissionStickyContainer;
};

const DocsFloatingToc: FC<FloatingTocProps & NullableClassName> = (props) => {
  const convertedProps = props as AppSubmissionFloatingTocProps;
  return <FloatingToc ftocVariant="docs" {...convertedProps} />;
};

const AppSubmissionFloatingToc: FC<
  AppSubmissionFloatingTocProps & NullableClassName
> = (props) => {
  return <FloatingToc ftocVariant="app-submission" {...props} />;
};

export { DocsFloatingToc, AppSubmissionFloatingToc };
