import { RenderableTreeNode } from '@markdoc/markdoc';
import { transformMarkdown } from '@squareup/dex-feature-markdown';
import HomeIcon from '@squareup/dex-icons/dex/misc/Home';
import {
  DocPage,
  MultiLanguageBlock,
  NavBlockPreviousNextSteps,
  NavItemSubCategory,
  TocSection,
} from '@squareup/dex-types-shared-docs';
import {
  MarkdownComponent,
  MarkdownNode,
} from '@squareup/dex-types-shared-markdown';
import {
  buildTocSections,
  findToc,
} from '@squareup/dex-ui-shared-markdown-toc';
import {
  getAncestorNavItemsForTarget,
  makeDocUrlRelative,
} from '@squareup/dex-utils-docs-navigation';
import React, { useMemo } from 'react';

interface MarkdownNodeWithMetadata {
  node: RenderableTreeNode;
  markdown: string;
  language?: string | undefined;
  key: string;
}

interface MarkdownNodesByLanguage {
  nodes: Array<MarkdownNodeWithMetadata>;
  language?: string | undefined;
  tocSections: Array<TocSection>;
}

const homeBreadcrumb = {
  href: '/docs',
  title: <HomeIcon />,
  label: 'Documentation homepage',
};

/**
 * For a given doc page, it finds all the unique languages in the content
 * Languages are determined by the different `programmingLanguage` fields
 * found in a `multi-language` block. In essence, writers can add `multi-language` blocks
 * to vary the content depending on the language. It is up to us to then display
 * those blocks ONLY when the language is selected.
 * @param docPage The doc page to run on
 * @returns An array of all available languages. It returns an empty array if there are
 * no multi-language code blocks.
 */
const useMultiLanguages = (docPage: DocPage | undefined) => {
  return useMemo(() => {
    const languages = [
      ...new Set(
        docPage?.content
          ?.filter(
            (content): content is MultiLanguageBlock =>
              content.type === 'multi-language'
          )
          .flatMap((block: MultiLanguageBlock) => {
            return block.blocks.flatMap(
              (langBlock) => langBlock.programmingLanguage
            );
          })
          // don't include anything with 'All', as it's the same as empty
          .filter((language) => language !== 'All') || []
      ),
    ];

    return languages;
  }, [docPage]);
};

interface UseMarkdownNodesOptions {
  highlightHtml?: boolean | undefined;
  extraComponents?: Array<MarkdownComponent> | undefined;
  extraNodes?: Array<MarkdownNode> | undefined;
}

/**
 * A hook to collect all the markdown as nodes in after the markdoc
 * [transform](https://markdoc.dev/docs/render#transform) step, which
 * we use to build TOC sections. We do this in an array, and we attach
 * the transformed markdown block with an optional language.
 * This is done to be able to later filter out the markdown blocks when
 * their language isn't currently selected.
 * @param docPage The doc page to process
 * @returns An array of transformed markdown blocks along with their programming language if available.
 * If it was a normal markdown block (not multi-language), we set the language to be undefined.
 */
const useMarkdownNodes = (
  docPage: DocPage | undefined,
  { highlightHtml, extraComponents, extraNodes }: UseMarkdownNodesOptions = {}
): Array<MarkdownNodeWithMetadata> => {
  return useMemo(() => {
    const markdownNodes: Array<MarkdownNodeWithMetadata> = [];

    if (!docPage?.content) {
      return markdownNodes;
    }

    for (const [i, contentBlock] of docPage.content.entries()) {
      if (contentBlock.type === 'markdown' && Boolean(contentBlock.markdown)) {
        markdownNodes.push({
          node: transformMarkdown(contentBlock.markdown, {
            highlightHtml,
            extraComponents,
            extraNodes,
          }),
          key: `${contentBlock.name}${i}`, // name is not always unique, so enforce uniqueness
          markdown: contentBlock.markdown,
        });
      } else if (contentBlock.type === 'multi-language') {
        for (const multiLangBlock of contentBlock.blocks) {
          markdownNodes.push({
            node: transformMarkdown(multiLangBlock.markdown, {
              highlightHtml,
              extraComponents,
              extraNodes,
            }),
            markdown: multiLangBlock.markdown,
            language:
              // All is the same as being undefined, as it's for all languages
              multiLangBlock.programmingLanguage === 'All'
                ? undefined
                : multiLangBlock.programmingLanguage,
            key: multiLangBlock.name,
          });
        }
      }
    }

    return markdownNodes;
  }, [docPage?.content, extraComponents, extraNodes, highlightHtml]);
};

/**
 * This takes in a set of markdown nodes tagged with their language, or tagged with no language.
 * It then puts them into the appropriate sections. For example, nodes with no language go into
 * ALL languages, and nodes with languages only go into their respective language.
 *
 * If there are no languages, we end up with an array of length 1
 * @param nodes A set of Markdown nodes tagged by language
 * @param languages A set of all languages
 * @returns An array of languages with all markdown nodes required
 */
const useMarkdownNodesSectionedByLanguage = (
  nodes: Array<MarkdownNodeWithMetadata>,
  languages: string[]
): Array<MarkdownNodesByLanguage> => {
  return useMemo(() => {
    const sections: Array<MarkdownNodesByLanguage> = [];
    if (languages.length === 0) {
      return [
        {
          nodes,
          tocSections: buildTocSections(nodes.map((node) => node.node)),
        },
      ];
    }

    for (const language of languages) {
      const nodesForLanguage = nodes
        .filter((node) => !node.language || node.language === language)
        .map((node) => node);

      sections.push({
        nodes: nodesForLanguage,
        language,
        tocSections: buildTocSections(
          nodesForLanguage.map((node) => node.node)
        ),
      });
    }

    return sections;
  }, [nodes, languages]);
};

/**
 * A convenient hook to build the TOC based on the current markdown nodes and
 * the current language.
 * If there is no language provided, due to there being no multi-languague blocks, we
 * process everything. Otherwise, we filter out to only the language selected, or any blocks
 * with no language defined, as that denotes them as being available to all.
 * @param markdownNodes A list of transformed markdown nodes, taken from the `useMarkdownNodes` hook
 * @param language The current selected language, or undefined if no language selected
 * @returns An array of TocSections related to the current language.
 */
const useTocSection = (
  markdownNodes: Array<MarkdownNodesByLanguage>,
  language?: string | undefined
): Array<TocSection> => {
  return useMemo(() => {
    if (!language || markdownNodes.length <= 1) {
      return markdownNodes[0]?.tocSections || [];
    }

    return (
      markdownNodes.find((node) => node.language === language)?.tocSections ||
      []
    );
  }, [markdownNodes, language]);
};

/**
 * Goes through all the markdown, and finds the {TOC} node.
 * Using this, we can get the depth and hide props, as well as determine if
 * we should even have a TOC on the page
 * @param markdownNodes An array of transformed markdown nodes, taken from the `useMarkdownNodes` hook
 * @returns The TOC node from markdown, or null if it doesn't exist
 */
const useTocNode = (markdownNodes: Array<MarkdownNodeWithMetadata>) => {
  return findToc(markdownNodes.map((node) => node.node));
};

/**
 * Goes through the doc page, and finds the next step blocks, if they exist.
 * There should never be more than one, so we just grab the first one in the content
 * @param docPage The doc page
 * @returns The NavBlockNextSteps, or undefined
 */
const useNextStepBlocks = (
  docPage: DocPage | undefined
): NavBlockPreviousNextSteps | undefined => {
  return docPage?.content?.find(
    (content): content is NavBlockPreviousNextSteps => {
      return content.type === 'next-steps';
    }
  );
};

const useBreadcrumbs = (
  navItem: NavItemSubCategory | undefined,
  slug: string
) => {
  return useMemo(() => {
    const ancestors = getAncestorNavItemsForTarget(navItem, slug);
    if (!ancestors || ancestors.length === 0) {
      return [];
    }

    return [
      homeBreadcrumb,
      ...ancestors.map((item) => {
        // Unfortunately, most nav item links come with `/` prepended.
        // That's wrong because it should have nothing prepended to ensure
        // it's relative to the docs site. Therefore, it it exists, strip it
        return {
          href: makeDocUrlRelative(item.url),
          title: item.title,
        };
      }),
    ];
  }, [navItem, slug]);
};

export {
  useTocSection,
  useMarkdownNodes,
  useMultiLanguages,
  useTocNode,
  useNextStepBlocks,
  useMarkdownNodesSectionedByLanguage,
  useBreadcrumbs,
  homeBreadcrumb,
};
