import {
  getEnvironmentName,
  getNextPublicBaseURL,
} from '@squareup/dex-utils-environment';
import { usePathname } from '@squareup/dex-utils-shared-routing-hooks';
import NextHead from 'next/head';
import React, { FunctionComponent } from 'react';

const trailingSlashRegex = /\/+$/;

export interface HeadProps {
  readonly description: string;
  readonly title: string;
  readonly canonicalPath?: string | undefined;
  readonly preconnectUrl?: string | undefined;
}

export const Head: FunctionComponent<HeadProps> = ({
  description,
  preconnectUrl,
  title,
  canonicalPath,
}) => {
  const { pathName, isReady } = usePathname();
  const url = `${getNextPublicBaseURL()}${isReady ? pathName : ''}`;
  const environment = getEnvironmentName();

  return (
    <NextHead>
      <title>{title}</title>
      <meta name="description" content={description} key="description" />
      {/*
       * Marketing to provide keyword copy, although this does not seem to be needed for SEO:
       * https://developers.google.com/search/docs/fundamentals/seo-starter-guide
       * <meta name="keywords" content="" key="keywords" />
       */}
      <meta name="environment" content={environment} key="environment" />
      <meta
        property="og:site_name"
        content="Square Developer"
        key="og:site_name"
      />
      <meta property="og:title" content={title} key="og:title" />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      <meta property="og:url" content={url} key="og:url" />
      <meta name="twitter:card" content="summary" key="twitter:card" />
      <meta name="twitter:site" content="@SquareDev" key="twitter:site" />
      <meta name="twitter:creator" content="@SquareDev" key="twitter:creator" />
      <meta name="twitter:title" content={title} key="twitter:title" />
      <meta name="twitter:url" content={url} key="twitter:url" />
      <meta
        name="twitter:description"
        content={description}
        key="twitter:description"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=1"
        key="viewport"
      />
      {environment === 'development' ? (
        <link rel="shortcut icon" href="/dev-favicon.png" key="icon" />
      ) : (
        <link rel="shortcut icon" href="/favicon.ico" key="icon" />
      )}
      {preconnectUrl ? (
        <link
          rel="preconnect"
          href={preconnectUrl}
          crossOrigin="use-credentials"
          key="preconnect-link"
        />
      ) : null}
      {preconnectUrl ? (
        <link rel="dns-prefetch" href={preconnectUrl} key="dns-prefetch-link" />
      ) : null}
      {canonicalPath ? (
        <link
          rel="canonical"
          href={`${getNextPublicBaseURL()}${canonicalPath.replace(
            trailingSlashRegex,
            ''
          )}`}
        />
      ) : null}
    </NextHead>
  );
};
