import { getDeveloperApi } from '@squareup/dex-data-shared-developer-api';
import {
  Question,
  Requirement,
  QuestionResponse,
  ShortAnswersState,
  Option,
  SelectedOptionsState,
  SavedShortAnswers,
  SavedSelectedOptions,
  SavedSelectedRequirements,
} from '@squareup/dex-types-shared-app-launch';
import {
  useEffect,
  useState,
  useCallback,
  useRef,
  MutableRefObject,
} from 'react';

import styles from '../../components/requirement-question/requirement-question.module.css';
import {
  setAnsweredQuestionsFromShortAnswers,
  setAnsweredQuestionsFromSelectedOptionsOnLoad,
  getQuestionAndFollowUps,
  getQuestionToOptionSetMap,
  isEmptyString,
  QuestionsAndRequirementsResp,
} from '../get-questions-utils';
import { useRequirements } from '../use-requirements';

interface SearchQuestionQueryRequest {
  questionIds: string[];
  preview?: boolean;
  environment?: string;
}

interface UseRequirementDocsInitialConfig {
  environment?: string;
  preview?: boolean;
}

interface UseRequirementDocsInitialStates {
  domainId: string;
  baseQuestions: Array<Question>;
  baseRequirements: Array<Requirement>;
  saveData: (
    domainId: string,
    data: {
      selectedOptions?: SelectedOptionsState;
      shortAnswers?: ShortAnswersState;
    }
  ) => void;
  presavedData:
    | {
        savedShortAnswers?: SavedShortAnswers;
        savedSelectedOptions?: SavedSelectedOptions;
        savedSelectedRequirements?: SavedSelectedRequirements;
      }
    | undefined;
  config?: UseRequirementDocsInitialConfig;
}

/**
 * useRequirementDocs is a custom hook that accepts the following the initial values for
 * the list of questions (baseQuestions), requirements (baseRequirements), and presavedData with
 * previously saved selected options, short answers and selected requirements.
 *
 * useRequirementDocs itself uses React hooks and custom hooks to compartamentalize logic
 * that is would only apply to the state returned by the custom hook. For any logic, that relies on multiple states,
 * we create a useEffect function within useRequirementDocs.
 *
 * returns:
 * - questions list
 * - requirements list
 * - selected options set
 * - short answers map
 * - handler to update the selected options set
 * - handler to update the short answer questions map
 * - percent of questions answered
 */
function useRequirementDocs({
  domainId,
  baseQuestions,
  baseRequirements,
  config,
  presavedData,
  saveData,
}: UseRequirementDocsInitialStates) {
  const [errorLoadingQuestion, setErrorLoadingQuestion] = useState(false);
  const timeoutId = useRef<number | undefined>(undefined);
  // Keep track on if we've fetched requirements yet or not
  const [questions, setQuestions] = useState<Array<Question>>(baseQuestions);
  const {
    requirements,
    selectedRequirements,
    setRequirements,
    setSelectedRequirements,
    onRequirementSelected,
  } = useRequirements(domainId, baseRequirements);
  const [selectedOptions, setSelectedOptions] = useState(new Set<string>());
  const [shortAnswers, setShortAnswers] = useState(new Map<string, string>());
  const [loadedSaved, setLoadedSaved] = useState(false);
  const [loadingInProgress, setLoadingInProgress] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState(new Set<string>());

  const questionRefs: MutableRefObject<Map<string, HTMLElement | null>> =
    useRef<Map<string, HTMLElement>>(new Map<string, HTMLElement>());
  const [searchQuestionQuery] = getDeveloperApi().useLazySearchQuestionQuery();

  const searchQuestions = useCallback(
    (questionIds: string[]): Promise<QuestionResponse> => {
      const request: SearchQuestionQueryRequest = {
        questionIds,
        preview: Boolean(config?.preview),
      };
      if (config?.environment) {
        request.environment = config.environment;
      }

      // Fetch cached value if available
      return searchQuestionQuery(request, true).unwrap();
    },
    [searchQuestionQuery, config]
  );

  const cleanUpAndSetAnswers = useCallback(
    (
      removedQuestions: Question[],
      newSelectedOptions: SelectedOptionsState,
      newShortAnswers: ShortAnswersState
    ) => {
      let finalSelectedOptions = new Set<string>();
      let finalShortAnswers = new Map<string, string>();
      if (removedQuestions.length === 0) {
        finalSelectedOptions = newSelectedOptions;
        finalShortAnswers = newShortAnswers;
      } else {
        const removedOptions = new Set<string>();
        const removedShortAnswer = new Set<string>();
        removedQuestions.forEach((removedQuestion) => {
          if (removedQuestion.type === 'ShortAnswer') {
            removedShortAnswer.add(removedQuestion.id);
          } else {
            removedQuestion.options?.forEach((option) =>
              removedOptions.add(option.id)
            );
          }
        });

        const cleanedUpSelectedOptions = new Set<string>(
          [...newSelectedOptions].filter((id) => !removedOptions.has(id))
        );
        const cleanedUpShortAnswers = new Map<string, string>(
          [...newShortAnswers].filter(([id]) => !removedShortAnswer.has(id))
        );
        finalSelectedOptions = cleanedUpSelectedOptions;
        finalShortAnswers = cleanedUpShortAnswers;
      }

      setSelectedOptions(finalSelectedOptions);
      setShortAnswers(finalShortAnswers);
      if (loadedSaved) {
        saveData(domainId, {
          selectedOptions: finalSelectedOptions,
          shortAnswers: finalShortAnswers,
        });
      }
    },
    [loadedSaved, saveData, domainId]
  );

  const updateQuestionsRequirements = useCallback(
    async (
      newSelectedOptions: SelectedOptionsState,
      newShortAnswers: ShortAnswersState
    ) => {
      const newQuestions: Array<Question> = [];
      const newRequirements: Map<string, Requirement> = new Map(
        baseRequirements.map((requirement) => [requirement.id, requirement])
      );

      let foundErrorLoadingQuestion: boolean = false;

      const promises: Array<Promise<QuestionsAndRequirementsResp>> = [];
      for (const q of baseQuestions) {
        promises.push(
          getQuestionAndFollowUps(q, newSelectedOptions, searchQuestions)
        );
      }
      const results = await Promise.all(promises);
      results.forEach((result: QuestionsAndRequirementsResp) => {
        if (result.questions.length > 0) {
          if (result.foundError) {
            foundErrorLoadingQuestion = true;
          }
          newQuestions.push(...result.questions);
          result.requirements.forEach((requirement) => {
            // Check for any duplicate requirements
            if (!newRequirements.has(requirement.id)) {
              newRequirements.set(requirement.id, requirement);
            }
          });
        }
      });

      const questionSet = new Set(newQuestions.map((q) => q.id));
      const removedQuestions = questions.filter((q) => !questionSet.has(q.id));

      // If we're loading these questions for the first time we don't want to apply the fade in animation.
      if (!loadedSaved) {
        newQuestions.forEach((newQ) => questionRefs.current.set(newQ.id, null));

        const questionToOptionSetMap = getQuestionToOptionSetMap(newQuestions);
        setAnsweredQuestions((answeredQuestions) => {
          let newAnsweredQuestions =
            setAnsweredQuestionsFromSelectedOptionsOnLoad(
              newSelectedOptions,
              questionToOptionSetMap,
              new Set(answeredQuestions)
            );
          newAnsweredQuestions = setAnsweredQuestionsFromShortAnswers(
            newShortAnswers,
            questionSet,
            newAnsweredQuestions
          );
          return newAnsweredQuestions;
        });
      }

      // Apply animation on question removal
      if (removedQuestions.length > 0) {
        // Cancel previous timeout
        if (timeoutId.current !== undefined) {
          clearTimeout(timeoutId.current);
        }
        removedQuestions.forEach((removedQ) => {
          if (questionRefs.current.has(removedQ.id)) {
            questionRefs.current
              .get(removedQ.id)
              ?.classList.add(styles['remove-item'] || '');
          }

          setAnsweredQuestions((answeredQuestions) => {
            const newAnsweredQuestions = new Set(answeredQuestions);
            newAnsweredQuestions.delete(removedQ.id);
            return newAnsweredQuestions;
          });
        });

        timeoutId.current = window.setTimeout(
          () => setQuestions(newQuestions),
          500
        );
      } else {
        setQuestions(newQuestions);
      }
      cleanUpAndSetAnswers(
        removedQuestions,
        newSelectedOptions,
        newShortAnswers
      );

      setErrorLoadingQuestion(foundErrorLoadingQuestion);
      setRequirements([...newRequirements.values()]);
    },
    [
      baseRequirements,
      questions,
      loadedSaved,
      cleanUpAndSetAnswers,
      setRequirements,
      baseQuestions,
      searchQuestions,
    ]
  );

  const onOptionSelected = (option: Option, parentQuestion: Question): void => {
    const newSelectedOptions = new Set<string>(selectedOptions);
    const newAnsweredQuestions = new Set(answeredQuestions);

    if (
      newSelectedOptions.has(option.id) &&
      parentQuestion.type === 'MultiSelect'
    ) {
      newSelectedOptions.delete(option.id);

      let noOptionSelected = true;
      // if last option of multiselect question is deselected
      for (const childOption of parentQuestion.options || []) {
        if (newSelectedOptions.has(childOption.id)) {
          noOptionSelected = false;
          break;
        }
      }
      if (noOptionSelected) {
        newAnsweredQuestions.delete(parentQuestion.id);
      }
    } else {
      newSelectedOptions.add(option.id);

      if (parentQuestion.type === 'SingleSelect') {
        const unSelectedOption = parentQuestion.options?.find(
          (o: Option) => o.id !== option.id && newSelectedOptions.has(o.id)
        );
        unSelectedOption && newSelectedOptions.delete(unSelectedOption.id);
      }

      newAnsweredQuestions.add(parentQuestion.id);
    }

    setAnsweredQuestions(newAnsweredQuestions);
    updateQuestionsRequirements(newSelectedOptions, shortAnswers);
  };

  const onShortAnswerChange = (
    newValue: string,
    parentQuestion: Question
  ): void => {
    const newShortAnswers = new Map<string, string>(shortAnswers);
    const newAnsweredQuestions = new Set(answeredQuestions);
    newShortAnswers.set(parentQuestion.id, newValue);
    setShortAnswers(newShortAnswers);
    saveData(domainId, { shortAnswers: newShortAnswers, selectedOptions });

    if (isEmptyString(newValue)) {
      newAnsweredQuestions.delete(parentQuestion.id);
    } else if (!newAnsweredQuestions.has(parentQuestion.id)) {
      newAnsweredQuestions.add(parentQuestion.id);
    }
    setAnsweredQuestions(newAnsweredQuestions);
  };

  const loadFromSavedStorage = useCallback(async () => {
    if (!presavedData) {
      setLoadedSaved(true);
      return;
    }

    setLoadingInProgress(true);
    const {
      savedShortAnswers,
      savedSelectedRequirements,
      savedSelectedOptions,
    } = presavedData;

    if (savedSelectedRequirements) {
      setSelectedRequirements(new Set(savedSelectedRequirements));
    }

    const newShortAnswers = savedShortAnswers
      ? new Map(Object.entries(savedShortAnswers))
      : new Map(shortAnswers);
    const newSelectedOptions = savedSelectedOptions
      ? new Set(savedSelectedOptions)
      : new Set(selectedOptions);

    // Process saved selected options retrieve followup questions
    await updateQuestionsRequirements(newSelectedOptions, newShortAnswers);

    // Once we've finished loading all resources set to done.
    setLoadedSaved(true);
    setLoadingInProgress(false);
  }, [
    presavedData,
    setSelectedRequirements,
    shortAnswers,
    selectedOptions,
    updateQuestionsRequirements,
  ]);

  // If loaded already or in progress, end early.
  useEffect(() => {
    if (loadedSaved || loadingInProgress) {
      return;
    }

    loadFromSavedStorage();
  }, [loadedSaved, loadingInProgress, loadFromSavedStorage]);

  const percentAnswered = (answeredQuestions.size / questions.length) * 100;

  return {
    questions,
    requirements,
    selectedOptions,
    shortAnswers,
    errorLoadingQuestion,
    onOptionSelected,
    onShortAnswerChange,
    selectedRequirements,
    onRequirementSelected,
    percentAnswered,
    questionRefs,
    isInitialLoadComplete: loadedSaved,
  };
}

export {
  useRequirementDocs,
  type UseRequirementDocsInitialStates,
  type UseRequirementDocsInitialConfig,
  type SearchQuestionQueryRequest,
};
