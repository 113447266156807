import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Grid, GridItemRenderProps } from '@squareup/dex-ui-shared-grid';
import { TrackedBox } from '@squareup/dex-ui-user-interaction-publisher';
import clsx from 'clsx';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import pageStyles from './page-layout.module.css';

export type DocPageGridType = 'ftoc' | 'requirements' | undefined;

interface DocsPageContentLayoutProps {
  rightSidebar?: ReactNode | undefined;
  gridType?: DocPageGridType | undefined;
  feedbackPrompt?: ReactNode | undefined;
}

const DocsPageContentLayout: FC<
  PropsWithChildren<DocsPageContentLayoutProps & NullableClassName>
> = ({ children, rightSidebar, gridType, feedbackPrompt }) => {
  const render = ({ styles: gridStyles }: GridItemRenderProps) => {
    return (
      <>
        <TrackedBox
          className={clsx(
            pageStyles['content-main-grid-item'],
            pageStyles['grid-item-column-autosizing'],
            rightSidebar
              ? gridStyles['grid-item-medium']
              : gridStyles['grid-item-full'],
            rightSidebar && pageStyles['limit-width']
          )}
          trackingId="main"
        >
          {children}
        </TrackedBox>
        {rightSidebar && (
          <TrackedBox
            margin={{ top: '8x' }}
            className={clsx(
              gridType === 'requirements'
                ? gridStyles['grid-item-medium']
                : gridStyles['grid-item-small'],
              gridType === 'ftoc' && pageStyles['sidebar-floating-toc'],
              gridType === 'requirements' && pageStyles['sidebar-requirements']
            )}
            trackingId="right-sidebar"
          >
            {rightSidebar}
          </TrackedBox>
        )}
        <TrackedBox
          className={clsx(
            rightSidebar
              ? gridStyles['grid-item-full']
              : gridStyles['grid-item-across']
          )}
          trackingId="feedback"
        >
          {feedbackPrompt}
        </TrackedBox>
      </>
    );
  };

  return (
    <Grid
      className={clsx(
        gridType === 'requirements' && pageStyles['grid-requirements']
      )}
      variant="docs-content"
      render={render}
      testId="docs-content-layout"
    />
  );
};

export { DocsPageContentLayout };
