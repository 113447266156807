import { Markdown, parseFrontmatter } from '@squareup/dex-feature-markdown';
import {
  QuestionsList,
  RequirementsList,
  useRequirementDocs,
  UseRequirementDocsInitialConfig,
  useSavedAnswers,
} from '@squareup/dex-feature-shared-main-requirement-docs';
import { Spinner } from '@squareup/dex-icons/dex/misc';
import { useMarkdownContext } from '@squareup/dex-markdown-context-provider';
import {
  useHtmlHighlight,
  useIsPreview,
  useShowMarkdown,
} from '@squareup/dex-store-access-dex-tech-docs-selectors';
import {
  Domain,
  Question,
  SavedSelectedOptions,
  SavedSelectedRequirements,
  SavedShortAnswers,
  SelectedOptionsState,
  ShortAnswersState,
} from '@squareup/dex-types-shared-app-launch';
import { DocPage, NavSet } from '@squareup/dex-types-shared-docs';
import { NextStepBlock, TabAnimator, Tabset, Head } from '@squareup/dex-ui';
import {
  DocsPageContentLayout,
  DocPageGridType,
} from '@squareup/dex-ui-dex-tech-docs-page-layout';
import { Box } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import { MarkdownTocProvider } from '@squareup/dex-ui-shared-markdown-toc';
import { useToaster } from '@squareup/dex-ui-shared-market';
import { DocsFloatingToc } from '@squareup/dex-ui-shared-table-of-contents';
import { useLeftNavItemsForPage } from '@squareup/dex-utils-docs-navigation';
import { getNextPublicDocsNameOrDefault } from '@squareup/dex-utils-environment';
import clsx from 'clsx';
import React, {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { DocFeedbackPrompt } from './DocFeedbackPrompt';
import { DocPageHeading } from './DocPageHeading';
import styles from './content.module.css';
import {
  useBreadcrumbs,
  useMarkdownNodes,
  useMarkdownNodesSectionedByLanguage,
  useMultiLanguages,
  useNextStepBlocks,
  useTocNode,
  useTocSection,
} from './markdown-block-hooks';

const LoadingSpinner: FC = () => {
  return (
    <Box className={styles['spinner-container']}>
      <Spinner
        className={clsx(commonIconStyles['icon-spinner'], styles['spinner'])}
      />
    </Box>
  );
};

interface RequirementDocsPageContentProps {
  requirementDomain: Domain;
  nextStepBlocksNode: ReactNode;
  feedbackNode?: ReactNode | undefined;
  headingNode: ReactNode;
  useRequirementDocsConfig: UseRequirementDocsInitialConfig;
  showEditLinks: boolean;
  markdown: React.JSX.Element;
  saveData: (
    domainId: string,
    data: {
      selectedOptions?: SelectedOptionsState;
      shortAnswers?: ShortAnswersState;
    }
  ) => void;
  presavedData: {
    savedShortAnswers?: SavedShortAnswers;
    savedSelectedOptions?: SavedSelectedOptions;
    savedSelectedRequirements?: SavedSelectedRequirements;
  };
}

const RequirementDocsPageContent: FC<RequirementDocsPageContentProps> = ({
  markdown,
  headingNode,
  presavedData,
  feedbackNode,
  requirementDomain,
  nextStepBlocksNode,
  useRequirementDocsConfig,
  showEditLinks,
  saveData,
}) => {
  const { showToast } = useToaster();

  const {
    questions,
    requirements,
    selectedOptions,
    shortAnswers,
    errorLoadingQuestion,
    onOptionSelected,
    onShortAnswerChange,
    selectedRequirements,
    onRequirementSelected,
    isInitialLoadComplete,
    questionRefs,
  } = useRequirementDocs({
    config: useRequirementDocsConfig,
    saveData,
    domainId: requirementDomain.id,
    presavedData,
    baseQuestions: requirementDomain.questions ?? [],
    baseRequirements: requirementDomain.requirements ?? [],
  });

  useEffect(() => {
    if (errorLoadingQuestion) {
      showToast({
        message:
          'There was an error generating content on this page. Try again later to ensure your requirements are accurate.',
        variant: 'critical',
      });
    }
  }, [errorLoadingQuestion, showToast]);

  const filteredQuestions = useMemo<Array<Question>>(
    () => questions.filter((q) => !q.isDashboardSpecific),
    [questions]
  );

  const rightSidebar = (
    <RequirementsList
      isLoading={!isInitialLoadComplete}
      requirements={requirements}
      domainName={requirementDomain.title}
      selectedRequirements={selectedRequirements}
      onRequirementSelected={onRequirementSelected}
    />
  );

  return (
    <DocsPageContentLayout
      rightSidebar={rightSidebar}
      gridType={'requirements'}
      feedbackPrompt={feedbackNode}
    >
      <>
        {headingNode}
        <Box>
          {markdown}
          <Box margin={{ top: '3x' }}>
            {isInitialLoadComplete ? (
              <QuestionsList
                questions={filteredQuestions}
                selectedOptions={selectedOptions}
                shortAnswers={shortAnswers}
                onOptionSelected={onOptionSelected}
                onShortAnswerChange={onShortAnswerChange}
                domainName={requirementDomain.title}
                questionsRefs={questionRefs}
                showEditLinks={showEditLinks}
                trackingId="app-launch-req-doc-questionnaire"
              />
            ) : (
              <LoadingSpinner />
            )}
          </Box>
          {nextStepBlocksNode}
        </Box>
      </>
    </DocsPageContentLayout>
  );
};

interface DocsPageProps {
  docPage: DocPage;
  navSet?: NavSet | undefined;
  disableFeedback?: boolean | undefined;
  requirementDomain?: Domain | undefined;
  contentfulEnvironment?: string | undefined;
  releaseTrain?: string | undefined;
}

const DocsPageContent: FC<DocsPageProps> = ({
  docPage,
  navSet,
  disableFeedback = false,
  requirementDomain,
  contentfulEnvironment,
  releaseTrain,
}) => {
  const markdownContentRef = useRef<HTMLElement>(null);
  const showMarkdown = useShowMarkdown();
  const highlightHtml = useHtmlHighlight();
  const { extraComponents, extraNodes } = useMarkdownContext();
  const {
    saveShortAnswers,
    saveSelectedOptions,
    readFromStorage: finishedLoadingFromStorage,
    savedShortAnswers,
    savedSelectedOptions,
    savedSelectedRequirements,
  } = useSavedAnswers(requirementDomain?.id || '');

  const languages = useMultiLanguages(docPage);
  const markdownNodes = useMarkdownNodes(docPage, {
    highlightHtml,
    extraComponents,
    extraNodes,
  });

  const frontmatter = parseFrontmatter({
    nodes: markdownNodes.map((node) => node.node),
  });

  // Make sure every tab has all the appropriate nodes. For example,
  // nodes without a language go into both sections
  const markdownNodesByLanguage = useMarkdownNodesSectionedByLanguage(
    markdownNodes,
    languages
  );

  const nextStepBlocks = useNextStepBlocks(docPage);

  const navItem = useLeftNavItemsForPage(docPage.leftNavId, navSet);
  const breadcrumbs = useBreadcrumbs(navItem, docPage.slug);

  const [currentTabId, setCurrentTabId] = useState<string | undefined>(
    languages?.[0] || undefined
  );

  const tocSections = useTocSection(markdownNodesByLanguage, currentTabId);
  const tocNode = useTocNode(markdownNodes);

  const onTabChanged = useCallback(
    (newTabId: string) => {
      setCurrentTabId(newTabId);
    },
    [setCurrentTabId]
  );

  const ftocSections = tocSections.filter((section) => section.level < 3);
  const ftocEnabled = !tocNode?.disabled && ftocSections.length > 1;

  const isPreview = useIsPreview();

  const saveData = (
    _: string,
    data: {
      selectedOptions?: SelectedOptionsState;
      shortAnswers?: ShortAnswersState;
    }
  ) => {
    if (data.selectedOptions) {
      saveSelectedOptions(data.selectedOptions);
    }

    if (data.shortAnswers) {
      saveShortAnswers(data.shortAnswers);
    }
  };

  const config: UseRequirementDocsInitialConfig = {
    preview: isPreview || Boolean(releaseTrain),
  };
  if (contentfulEnvironment && contentfulEnvironment !== '') {
    config.environment = contentfulEnvironment;
  }

  let rightSidebar: ReactNode | undefined;
  let gridType: DocPageGridType | undefined;

  if (ftocEnabled) {
    gridType = 'ftoc';
    rightSidebar = (
      <DocsFloatingToc
        headerTitle="On this page"
        sections={ftocSections}
        mainContentRef={markdownContentRef}
      />
    );
  }

  const headingNode = useMemo(() => {
    return (
      <>
        <Head
          title={
            docPage?.heading?.browserTitle || getNextPublicDocsNameOrDefault()
          }
          description={
            docPage?.searchSummary || docPage?.heading?.summary || ''
          }
          canonicalPath={`/docs/${docPage.slug}`}
        />
        <DocPageHeading
          heading={docPage.heading}
          breadcrumbItems={breadcrumbs}
          colorTheme={frontmatter?.pageColorTheme}
        />
      </>
    );
  }, [
    breadcrumbs,
    docPage.heading,
    docPage?.searchSummary,
    docPage.slug,
    frontmatter?.pageColorTheme,
  ]);

  const tabsWithMarkdownNode = useMemo(() => {
    return (
      <>
        {languages.length > 1 && (
          <Tabset
            tabs={languages.map((lang) => {
              return {
                id: lang,
                label: lang,
              };
            })}
            currentTabId={currentTabId || ''}
            onTabChanged={onTabChanged}
          ></Tabset>
        )}
        <TabAnimator
          currentTab={currentTabId || ''}
          tabs={markdownNodesByLanguage.map((tabContent) => {
            const contents = tabContent.nodes.map((node) => {
              const markdownFragment = showMarkdown ? (
                <Box
                  margin={{ vertical: '2x' }}
                  as="pre"
                  className={styles['show-markdown-pre']}
                >
                  {node.markdown}
                </Box>
              ) : (
                <Markdown renderTreeNode={node.node} />
              );

              return (
                <Fragment key={node.key}>
                  <MarkdownTocProvider
                    value={{ sections: tabContent.tocSections }}
                  >
                    {markdownFragment}
                  </MarkdownTocProvider>
                </Fragment>
              );
            });
            return {
              tabId: tabContent.language,
              contents,
            };
          })}
        />
      </>
    );
  }, [
    currentTabId,
    languages,
    markdownNodesByLanguage,
    onTabChanged,
    showMarkdown,
  ]);

  const nextStepBlocksNode = useMemo(() => {
    return (
      nextStepBlocks && (
        <Box margin={{ top: '5x' }}>
          <NextStepBlock
            previous={nextStepBlocks.previousBlock}
            next={nextStepBlocks.nextBlock}
          />
        </Box>
      )
    );
  }, [nextStepBlocks]);

  const feedbackNode = useMemo(() => {
    return !disableFeedback && <DocFeedbackPrompt />;
  }, [disableFeedback]);

  if (requirementDomain !== undefined) {
    if (finishedLoadingFromStorage) {
      return (
        <RequirementDocsPageContent
          headingNode={headingNode}
          feedbackNode={feedbackNode}
          requirementDomain={requirementDomain}
          nextStepBlocksNode={nextStepBlocksNode}
          useRequirementDocsConfig={config}
          showEditLinks={showMarkdown}
          markdown={tabsWithMarkdownNode}
          presavedData={{
            savedShortAnswers,
            savedSelectedRequirements,
            savedSelectedOptions,
          }}
          saveData={saveData}
        />
      );
    } else {
      return <LoadingSpinner />;
    }
  } else {
    return (
      <DocsPageContentLayout
        rightSidebar={rightSidebar}
        gridType={gridType}
        feedbackPrompt={feedbackNode}
      >
        <>
          {headingNode}
          <Box ref={markdownContentRef}>
            {tabsWithMarkdownNode}
            {nextStepBlocksNode}
          </Box>
        </>
      </DocsPageContentLayout>
    );
  }
};

DocsPageContent.displayName = 'DocsPageContent';

export { DocsPageContent };
