import { IncomingHttpHeaders } from 'http';

import { ServerDataApi } from '@squareup/dex-data-shared-data-api';
import {
  developerDataApiBaseQuery,
  developerDataEndpoints,
} from '@squareup/dex-data-shared-developer-api';
import { SQFlagsFeatureProducer } from '@squareup/dex-data-shared-sq-flags-producer';
import { StoreStateNode } from '@squareup/dex-types-data-state';
import { ServerAppInitResults } from '@squareup/dex-types-shared-app';
import { Flags } from '@squareup/dex-types-shared-developer-api';
import { UserInfo } from '@squareup/dex-types-shared-developer-data-api';
import {
  TaskEvent,
  initTaskEvent,
} from '@squareup/dex-utils-application-behavior-events';
import {
  getServerDataApiKey,
  isPublicContext,
  isServerContext,
} from '@squareup/dex-utils-environment';
import {
  publishActionableError,
  settledResultToStoreStateNode,
  stateErrorToCustomError,
  unhandledToInternalServerError,
} from '@squareup/dex-utils-error';
import {
  BaseQueryArgs,
  mergeQueryArgs,
} from '@squareup/dex-utils-shared-base-api';
import { savtCookieName } from '@squareup/dex-utils-shared-cookie';

type ServerAppInitProps = {
  requestId: string;
  headers?: IncomingHttpHeaders | undefined;
  cookies?: Partial<{ [key: string]: string }>;
};

const taskEvent: TaskEvent = {
  context: 'application',
  action: 'task',
  onType: 'util',
  onIdentifier: 'server-app-init',
  status: 'start',
};

const ERROR_CONTEXT = 'server app init';

async function getMeInfo(queryArgs: BaseQueryArgs) {
  let results: StoreStateNode<{ data: UserInfo }> = { isLoading: true };

  try {
    const meResult = await developerDataApiBaseQuery<UserInfo>({
      ...mergeQueryArgs(
        developerDataEndpoints.getUserInfo.query?.(),
        queryArgs
      ),
    });

    if (meResult.error) {
      results = {
        error: stateErrorToCustomError(meResult.error),
      };
    } else {
      results = meResult;
    }
  } catch (error) {
    results = {
      error: unhandledToInternalServerError(error, 'HYBRID_APP_USER_INFO'),
    };
  }

  return results;
}

async function getSQFlags(queryArgs: BaseQueryArgs) {
  const sqFlags = new SQFlagsFeatureProducer();

  try {
    await sqFlags.initialize(queryArgs);
    return sqFlags.getState();
  } catch (error) {
    return {
      error: unhandledToInternalServerError(error, ERROR_CONTEXT),
    };
  }
}

async function trackBotScore(
  headers: IncomingHttpHeaders | undefined,
  cookies?: Partial<{ [key: string]: string }>,
  merchantId?: string
): Promise<void> {
  if (!isPublicContext() || !isServerContext()) {
    return;
  }

  if (!cookies || !headers) {
    return;
  }

  const dataApi = new ServerDataApi(getServerDataApiKey());

  const avt = cookies[savtCookieName];
  if (!avt) {
    return;
  }

  const botScore = headers['x-cdn-bot-score'] as string;
  if (!botScore) {
    return;
  }

  const botScoreInt = Number.parseInt(botScore, 10);
  if (!Number.isInteger(botScoreInt)) {
    return;
  }

  const opts: Parameters<typeof dataApi.track>[0] = {
    entityType: 'merchant',
    eventName: 'devs_bot_score',
    anonymousId: avt,
    properties: {
      bot_score: botScoreInt,
    },
  };

  if (merchantId) {
    opts.entityId = merchantId;
  }

  await dataApi.track(opts);
}

/**
 * Does all initialization which is needed for the server application.
 * Initialization refers to state fetch/hydration of configured dependancies.
 * Server app refers to the application logic running only on the server.
 *
 * NOTE: This will no-op if on the client. There is no module level state persisted here.
 */
async function initializeApp(props: ServerAppInitProps) {
  let serverInitResults: ServerAppInitResults<UserInfo, Flags> = {
    ...props,
    data: { getFlags: { isLoading: true } },
  };

  // Ignore this on client-side transitions. Otherwise we will
  // be calling the flags endpoint every single request
  // The hybrid app init takes care of flags client-side
  if (!isServerContext()) {
    return serverInitResults;
  }

  const { end } = initTaskEvent(taskEvent);

  const cookie = props.headers?.cookie;
  const queryArgs = {
    additionalHeaders: { ...(cookie && { cookie }) },
  };

  const [getFlags, getUserInfo] = await Promise.allSettled([
    getSQFlags(queryArgs),
    // Only make the call if there's an actual cookie
    cookie ? getMeInfo(queryArgs) : Promise.resolve(undefined),
  ]);

  const getFlagsInfo = settledResultToStoreStateNode(
    getFlags,
    `${ERROR_CONTEXT} getFlags`
  );

  serverInitResults = {
    ...props,
    data: {
      getUserInfo: settledResultToStoreStateNode(
        getUserInfo,
        `${ERROR_CONTEXT} getUserInfo`
      ),
      getFlags: getFlagsInfo,
    },
  };

  trackBotScore(
    props.headers,
    props.cookies,
    serverInitResults.data.getUserInfo?.data?.merchant?.id
  );

  if (serverInitResults.data.getFlags?.error) {
    publishActionableError(serverInitResults.data.getFlags?.error);
  }

  if (serverInitResults.data.getUserInfo?.error) {
    publishActionableError(serverInitResults.data.getUserInfo?.error);
  }

  end();

  return serverInitResults;
}

export { initializeApp };
