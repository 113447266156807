import * as React from 'react';
import { SVGProps } from 'react';

const SvgCheckmarkCircle = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12a9 9 0 1 1 18.001.001A9 9 0 0 1 3 12Zm2 0c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7Zm3.71-1.2L11 13.09l4.31-4.29 1.41 1.41-5 5c-.2.19-.45.29-.71.29-.26 0-.51-.09-.71-.29l-3-3 1.41-1.41Z"
      fill="#7B61FF"
    />
  </svg>
);
export default SvgCheckmarkCircle;
