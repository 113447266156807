import {
  ShortAnswersState,
  SelectedOptionsState,
  Question,
  QuestionResponse,
  Requirement,
} from '@squareup/dex-types-shared-app-launch';

interface QuestionsAndRequirementsResp {
  questions: Array<Question>;
  requirements: Array<Requirement>;
  foundError: boolean;
}

function setAnsweredQuestionsFromShortAnswers(
  shortAnswers: ShortAnswersState,
  questionIdSet: Set<string>,
  answeredQuestions: Set<string>
) {
  const answeredQuestionsCopy = new Set(answeredQuestions);
  shortAnswers.forEach((answer, qid) => {
    if (answer && !isEmptyString(answer) && questionIdSet.has(qid)) {
      answeredQuestionsCopy.add(qid);
    }
  });
  return answeredQuestionsCopy;
}

function setAnsweredQuestionsFromSelectedOptionsOnLoad(
  selectedOptions: SelectedOptionsState,
  questionToOptionSetMap: Map<string, Set<string>>,
  answeredQuestions: Set<string>
) {
  const answeredQuestionsCopy = new Set(answeredQuestions);
  selectedOptions.forEach((option) => {
    questionToOptionSetMap.forEach((optionSet, qid) => {
      if (optionSet.has(option)) {
        // no removals needed because we're loading from scratch
        answeredQuestionsCopy.add(qid);
      }
    });
  });
  return answeredQuestionsCopy;
}

function getQuestionToOptionSetMap(questions: Question[]) {
  const questionToOptionSetMap = new Map<string, Set<string>>();
  questions.forEach((q) =>
    questionToOptionSetMap.set(q.id, new Set(q.options?.map((o) => o.id)))
  );
  return questionToOptionSetMap;
}

async function getQuestionAndFollowUps(
  question: Question,
  newSelectedOptions: SelectedOptionsState,
  searchQuestions: (questionIds: string[]) => Promise<QuestionResponse>
): Promise<QuestionsAndRequirementsResp> {
  const allFollowupQuestions = [];
  const newRequirements: Map<string, Requirement> = new Map();
  const seenQuestions = new Set<string>();
  let questionsToProcess: Array<Question> = [question];
  let foundErrorLoadingQuestion = false;
  while (questionsToProcess.length > 0) {
    const question = questionsToProcess.shift();
    if (!question || seenQuestions.has(question.id)) {
      // invalid question object or already seen, move on to next question
      continue;
    }
    seenQuestions.add(question.id);

    const promises: Array<Promise<QuestionResponse>> = [];
    question.options?.forEach((option) => {
      if (newSelectedOptions.has(option.id)) {
        // Look up followup questions for option
        if (option.followupQuestions?.length) {
          promises.push(searchQuestions(option.followupQuestions));
        }

        // Add selected option's requirement
        if (option.requirements?.length) {
          option.requirements.forEach((requirement) => {
            // Check for any duplicate requirements
            if (!newRequirements.has(requirement.id)) {
              newRequirements.set(requirement.id, requirement);
            }
          });
        }
      }
    });

    if (promises.length === 0) {
      // nothing to retrieve move on to the next question
      continue;
    }

    const followUpQuestions: Array<Question> = [];
    const results = await Promise.allSettled(promises);
    results.forEach((result: PromiseSettledResult<QuestionResponse>) => {
      if (result.status === 'fulfilled' && result.value.questions.length > 0) {
        followUpQuestions.push(...result.value.questions);
      } else if (result.status === 'rejected') {
        foundErrorLoadingQuestion = true;
      }
    });

    allFollowupQuestions.push(...followUpQuestions);
    questionsToProcess = [...followUpQuestions, ...questionsToProcess];
  }

  return {
    questions: [question, ...allFollowupQuestions],
    requirements: [...newRequirements.values()],
    foundError: foundErrorLoadingQuestion,
  };
}

function isEmptyString(str: string) {
  return str.trim() === '';
}

export {
  setAnsweredQuestionsFromShortAnswers,
  setAnsweredQuestionsFromSelectedOptionsOnLoad,
  getQuestionToOptionSetMap,
  getQuestionAndFollowUps,
  isEmptyString,
  type QuestionsAndRequirementsResp,
};
