import {
  Requirement,
  SelectedRequirementsState,
} from '@squareup/dex-types-shared-app-launch';
import { useCallback, useState } from 'react';

import { useSavedAnswers } from './use-saved-answers';

export function useRequirements(
  domainId: string,
  baseRequirements: Requirement[]
) {
  const { saveSelectedRequirements } = useSavedAnswers(domainId);

  const [requirements, setRequirements] =
    useState<Array<Requirement>>(baseRequirements);

  const [selectedRequirements, setSelectedRequirements] =
    useState<SelectedRequirementsState>(new Set());

  const onRequirementSelected = useCallback(
    (selectedId: string) => {
      const newSelectedRequirements = new Set(selectedRequirements);

      if (newSelectedRequirements.has(selectedId)) {
        newSelectedRequirements.delete(selectedId);
      } else {
        newSelectedRequirements.add(selectedId);
      }

      setSelectedRequirements(newSelectedRequirements);
      saveSelectedRequirements(newSelectedRequirements);
    },
    [selectedRequirements, saveSelectedRequirements]
  );

  return {
    requirements,
    selectedRequirements,
    setRequirements,
    setSelectedRequirements,
    onRequirementSelected,
  };
}
