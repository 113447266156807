import { RenderableTreeNode, Tag } from '@markdoc/markdoc';
import { TocSection } from '@squareup/dex-types-shared-docs';
import {
  accordion,
  heading,
} from '@squareup/dex-ui-shared-markdown-components';
import { toMarkdownHeadingHrefHash } from '@squareup/dex-utils-shared-markdown-helpers';

import { MarkdownTocProps, toc } from './MarkdownToc';

function findToc(
  treeNodes: Array<RenderableTreeNode>
): MarkdownTocProps | null {
  if (treeNodes.length === 0) {
    return null;
  }

  const tagNodes: Array<Tag> = treeNodes.filter((node): node is Tag =>
    Tag.isTag(node)
  );

  const tocNode = tagNodes.find(
    (node): node is Tag<string, MarkdownTocProps> =>
      node.name === toc.tag.schema.render
  );
  if (tocNode) {
    const attributes = tocNode.attributes;
    return {
      depth: attributes.depth,
      hide: attributes.hide,
      disabled: attributes.disabled,
    };
  }

  const children = tagNodes.flatMap((node) => node.children);
  return findToc(children);
}

/**
 * Collect all TOC sections according to the given depth.
 * Depth in this case refers to the heading level, where
 * 1 is an h1, 2 is an h2, etc. It will collect all headings of that
 * level and lower.
 * It will recursively go through the tree in a BFS fashion until there are
 * no more children to process.
 * @param treeNodes An array or Markdoc RenderableTreeNodes, which comes
 * from the `transform` step.
 * @param options A set of options to modify collection
 * @returns An array of TocSection objects
 */
function collectTocSections(
  treeNodes: Array<RenderableTreeNode>
): Array<TocSection> {
  if (treeNodes.length === 0) {
    return [];
  }

  const tagNodes = treeNodes.filter((node): node is Tag => Tag.isTag(node));
  const headings = tagNodes
    .map((node) => {
      if (
        node.name === accordion.component.tagName &&
        node.attributes?.heading?.[0]
      ) {
        return node.attributes?.heading?.[0] as Tag;
      } else {
        return node;
      }
    })
    .filter((node) => {
      return (
        node.name === heading.component?.name &&
        !node.attributes.disableAnchor &&
        typeof node.children?.[0] === 'string'
      );
    })
    .map((node) => {
      const name = node.children[0] as string;
      return {
        name,
        id: toMarkdownHeadingHrefHash(name),
        level: node.attributes.level,
      };
    });

  const children = tagNodes.flatMap((node) => node.children);

  return [...headings, ...collectTocSections(children)];
}

/**
 * Given an array of markdoc renderable tree node,
 * builds a toc with the appropriate TOC sections according
 * to the TOC component.
 *
 * This function will go through, find the TOC tag, and get the attributes.
 * It will then find all the H1s, H2s, and optionally H3s and return those sections.
 * @param treeNodes A list of the renderable tree nodes for the markdown doc
 * @returns An array of TOC sections
 */
function buildTocSections(
  treeNodes: Array<RenderableTreeNode>
): Array<TocSection> {
  return collectTocSections(treeNodes);
}

export { buildTocSections, findToc };
