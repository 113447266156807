import { publishUnhandledError } from '@squareup/dex-utils-application-behavior-events';
import { useCallback, useContext, useMemo } from 'react';

import { ToasterContext } from './ToasterContext';
import { Toast, createMarketToastElement } from './create-market-toast-element';

interface Toaster {
  /**
   * Accepts a `toast` used to construct a `<market-toast>` of which the
   * visibility and lifecycle are managed by MarketToastManager.
   */
  showToast(toast: Toast): void;
}

/**
 * A hook to simplify interaction with MarketToastManager, which provides
 * niceties such as toast stacking and dismissal timers.
 *
 * Unfortunately, MarketToastManager's `show` method requires a web component
 * element instead of a ReactNode. To abstract this away from component
 * implementations, this hook handles the creation of the web component as well
 * as its provision to the MarketToastMananger.
 *
 * @returns A `toaster`
 */
export const useToaster = (): Toaster => {
  const marketToaster = useContext(ToasterContext).getToaster();

  const showToast = useCallback(
    (toast: Toast) => {
      if (!marketToaster) {
        return;
      }

      const marketToastElement = createMarketToastElement(toast);

      marketToaster.show(marketToastElement).catch(publishUnhandledError);
    },
    [marketToaster]
  );

  return useMemo(
    () => ({
      showToast,
    }),
    [showToast]
  );
};
